<svelte:options
  customElement={{
    tag: "oc-auth-field-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      /*                                    */
      delegateFocus: true,
    }),
    props: {
      variant: { type: "String" },
      passwordVisible: { type: "Boolean", attribute: "password-visible" },
      showCounter: { type: "Boolean", attribute: "show-counter" },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./AuthFieldV1.types";
  import CounterV1 from "../../../common/components/CounterV1.svelte";

  usePropertyChange();

  export let variant: Props["variant"] = "default";
  export let passwordVisible: Props["passwordVisible"] = false;
  export let showCounter: Props["showCounter"] = false;

  export let host: HTMLElement;

  let inputElement: HTMLInputElement | null = host.querySelector("input");
  let value = "";
  let type = "";
  let maxlength: number | undefined;
  let minlength: number | undefined;
  let isPasswordField = false;

  const setValue = () => {
    value = inputElement?.value ?? "";
  };

  const setType = () => {
    const currentType = inputElement?.type ?? "";
    if (type !== currentType) {
      /*                                                                                      */
      isPasswordField = inputElement?.type === "password";
    }
    type = currentType;
  };

  const setMinLength = () => {
    minlength =
      inputElement?.minLength && inputElement.minLength >= 0 ? inputElement.minLength : undefined;
  };

  const setMaxLength = () => {
    maxlength =
      inputElement?.maxLength && inputElement.maxLength >= 0 ? inputElement.maxLength : undefined;
  };

  const setPlaceholder = () => {
    /*                                                                                                    */
    if (inputElement) inputElement.placeholder ||= " ";
  };

  const initValues = () => {
    setValue();
    setType();
    setMinLength();
    setMaxLength();
    setPlaceholder();
  };

  onMount(() => {
    initValues();

    const observer = new MutationObserver((changes) => {
      if (inputElement !== host.querySelector("input")) {
        inputElement = host.querySelector("input");
        initValues();
      }

      changes
        .filter(
          (change) => change.type === "attributes" && change.target === (inputElement as Node),
        )
        .forEach((change) => {
          if (change.attributeName === "type") {
            setType();
          } else if (change.attributeName === "minlength") {
            setMinLength();
          } else if (change.attributeName === "maxlength") {
            setMaxLength();
          } else if (change.attributeName === "placeholder") {
            setPlaceholder();
          }
        });
    });

    observer.observe(host, {
      childList: true,
      attributes: true,
      subtree: true,
      attributeFilter: ["type", "minlength", "maxlength", "placeholder"],
    });

    return () => observer.disconnect();
  });

  $: if (inputElement && isPasswordField) {
    type = passwordVisible ? "text" : "password";
    inputElement.type = type;
  }

  const togglePasswordVisibility = (ev: Event) => {
    passwordVisible = !passwordVisible;
    ev.stopPropagation();
    ev.preventDefault();
  };
</script>

<div
  class={`auth-field auth-field--${variant}`}
  class:auth-field--with-password={isPasswordField}
  on:input={setValue}
>
  <slot></slot>
  {#if isPasswordField}
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <oc-link-v2
      class="auth-field__password-toggle"
      variant="secondary"
      size={"75"}
      asButton={true}
      on:click={togglePasswordVisibility}
      ocAriaLabel={passwordVisible ? "Passwort ausblenden" : "Passwort anzeigen"}
      >{passwordVisible ? "Ausblenden" : "Anzeigen"}</oc-link-v2
    >
  {/if}
</div>

<div class="two-columns">
  <div>
    <slot name="error"></slot>
    <slot name="hint"></slot>
  </div>
  {#if showCounter}
    <CounterV1 counterValue={value} maxCounter={maxlength} minCounter={minlength} />
  {/if}
</div>

<style lang="scss" global>
  @use "../../../../../../node_modules/@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins";

  $autofill-background-color: #f0f4fc;
  $transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  $gap: 8px;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .auth-field {
    box-sizing: border-box;
    position: relative;

    &:hover ::slotted(input) {
      outline-width: 2px;
    }

    &:focus-within {
      ::slotted(input) {
        outline-width: 2px;
      }
      &:not(.auth-field--error) ::slotted(input) {
        outline-color: tokens.$oc-component-form-default-border-color-focus;
      }
    }

    ::slotted(input) {
      all: unset;
      box-sizing: border-box;

      background: tokens.$oc-component-form-background-color;
      outline: 1px solid tokens.$oc-component-form-default-border-color;

      padding: tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;
      width: 100%;

      color: tokens.$oc-component-form-field-input-color;

      &::placeholder {
        font: tokens.$oc-component-form-field-placeholder-font;
        color: tokens.$oc-component-form-field-placeholder-color;
        opacity: 0;
        transition: $transition;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        box-sizing: border-box;
      }
    }

    /*                                       */
    &--with-password ::slotted(input) {
      padding-right: calc(tokens.$oc-component-form-field-spacing-x + 75px + $gap);
    }

    ::slotted(label) {
      position: absolute;
      top: tokens.$oc-component-form-field-spacing-y;
      left: tokens.$oc-component-form-field-spacing-x;
      padding: 0 tokens.$oc-semantic-spacing-25;
      max-width: calc(100% - 2 * tokens.$oc-semantic-spacing-50);
      box-sizing: border-box;

      transition: $transition;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      cursor: text;
      font: tokens.$oc-component-form-label-font;
      background-color: tokens.$oc-component-form-background-color;
      border-radius: tokens.$oc-component-form-field-label-border-radius;
      color: tokens.$oc-component-form-default-label-color;
    }

    &__password-toggle {
      position: absolute;
      display: flex;
      justify-content: right;
      box-sizing: border-box;

      top: 0;
      bottom: 0;
      right: 0;
      min-width: 90px;

      cursor: pointer;

      padding: tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;

      & {
        @include mixins.focus-styles(tokens.$oc-semantic-focus-outline-radius-100);
      }
    }

    &--error {
      ::slotted(input) {
        outline-color: tokens.$oc-component-form-error-border-color;
      }

      ::slotted(label) {
        color: tokens.$oc-component-form-error-label-color;
      }
    }
  }

  ::slotted([slot="error"]) {
    font: tokens.$oc-component-form-validation-message-font;
    color: tokens.$oc-component-form-error-validation-message-color;
    word-break: break-word;
  }

  ::slotted([slot="hint"]) {
    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-default-hint-color;
    word-break: break-word;
  }

  .two-columns {
    display: grid;
    grid-column-gap: 16px;
    width: 100%;

    > .counter {
      margin-top: tokens.$oc-component-form-hint-gap-y;
      justify-self: end;
      grid-column-start: 2;
    }
  }
</style>
