import { createEventDispatcher } from "svelte";
import type { CamelCase, KebabCase, SnakeCase } from "type-fest";
import { sheet } from "./index";
import type { Events } from "./SheetV1.types";

/**
 *
 *
 */
type ExtractEvent<T extends string> = T extends `oc-${infer U}` ? CamelCase<U> : never;

/**
 *
 */
type EventNames = ExtractEvent<keyof Events>;

/**
 *
 */
type ToOcEventName<T extends EventNames> = `oc-${KebabCase<T>}` extends keyof Events
  ? `oc-${KebabCase<T>}`
  : never;

/**
 *
 */
export type ExtractDetail<T extends CustomEvent> = T extends CustomEvent<infer U> ? U : never;

/**
 *
 */
export type GetSheetDetailByName<T extends keyof Events> = ExtractDetail<Events[T]>;

const EVENTS: { [P in keyof Events as ExtractEvent<P>]: P } = {
  open: "oc-open",
  close: "oc-close",
  switch: "oc-switch",
  contentLoaded: "oc-content-loaded",
  contentLoadingError: "oc-content-loading-error",
} as const;

export const CLOSE_TYPES: { [P in CloseTypes as Uppercase<SnakeCase<P & string>>]: P } = {
  CLOSED_WITH_X: "closedWithX",
  CLOSED_WITH_CURTAIN_CLICK: "closedWithCurtainClick",
  CLOSED_WITH_DRAG: "closedWithDrag",
  CLOSED_WITH_ESCAPE: "closedWithEscape",
  CLOSED_PROGRAMMATICALLY: "closedProgrammatically",
};

export type CloseTypes =
  | "closedWithX"
  | "closedWithCurtainClick"
  | "closedWithDrag"
  | "closedWithEscape"
  | "closedProgrammatically";

type UseEventsReturn = {
  /**
 *
 *
 *
 *
 *
 */
  emitEvent: <T extends EventNames>(
    name: T,
    payload: ExtractDetail<Events[ToOcEventName<T>]>,
  ) => void;
};

/**
 *
 *
 *
 */
export function useEvents(): UseEventsReturn {
  const dispatch = createEventDispatcher<Events>();

  return {
    emitEvent(name, payload) {
      /*                                                                     */
      dispatch(EVENTS[name], payload);

      /*                                                                                     */
      sheet[`${name}Event`].emit(payload);
    },
  };
}
