<svelte:options
  customElement={{
    tag: "oc-spinner-v1",
    /*                                                         */
    /*         */
    extend: (CustomElementConstructor) =>
      class extends CustomElementConstructor {
        constructor() {
          super();
          this.host = this;
        }
      },
    props: {
      size: { type: "String", reflect: true },
      variant: { type: "String", reflect: true },
    },
  }}
/>

<script lang="ts">
  export let size = "100";
  export let variant = "inverted";
</script>

<!--TODO: load svg from url -->
{#if size === "200"}
  <svg viewBox="0 0 48 48" fill="none" xmlns="http:/*                   */
    <title>Ladevorgang</title>
    <circle cx="24" cy="24" r="22" />
    <path
      d="M2 24C2 18.1652 4.31785 12.5694 8.44365 8.44365C12.5695 4.31785 18.1652 2 24 2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
{:else if size === "100"}
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http:/*                   */
    <title>Ladevorgang</title>
    <circle cx="12" cy="12" r="11" />
    <path
      d="M1 12C1 9.08262 2.15893 6.28472 4.22183 4.22183C6.28473 2.15893 9.08262 1 12 1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
{:else if size === "50"}
  <svg viewBox="0 0 12 12" fill="none" xmlns="http:/*                   */
    <title>Ladevorgang</title>
    <circle cx="6" cy="6" r="5.5" />
    <path
      d="M0.5 6C0.5 4.54131 1.07946 3.14236 2.11091 2.11091C3.14236 1.07946 4.54131 0.5 6 0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  /**
 *
 */
  @keyframes spinner-turn {
    to {
      transform: rotate(1turn);
    }
  }

  svg {
    display: block;
    animation: spinner-turn tokens.$oc-component-spinner-animation-duration infinite
      tokens.$oc-component-spinner-animation-easing;
    @media (prefers-reduced-motion) {
      animation: spinner-turn calc(tokens.$oc-component-spinner-animation-duration * 2) infinite
        tokens.$oc-component-spinner-animation-easing;
    }
  }

  :host {
    display: block;
  }

  circle {
    opacity: tokens.$oc-component-spinner-track-opacity;
  }

  :host([variant="default"]) {
    stroke: tokens.$oc-component-spinner-default-fill-color;
  }
  :host([variant="inverted"]) {
    stroke: tokens.$oc-component-spinner-inverted-fill-color;
  }

  :host([size="200"]) {
    width: tokens.$oc-component-spinner-200-size;
    height: tokens.$oc-component-spinner-200-size;
    stroke-width: tokens.$oc-component-spinner-200-stroke-width;
  }
  :host([size="100"]) {
    width: tokens.$oc-component-spinner-100-size;
    height: tokens.$oc-component-spinner-100-size;
    stroke-width: tokens.$oc-component-spinner-100-stroke-width;
  }
  :host([size="50"]) {
    width: tokens.$oc-component-spinner-50-size;
    height: tokens.$oc-component-spinner-50-size;
    stroke-width: tokens.$oc-component-spinner-50-stroke-width;
  }
</style>
