<svelte:options
  customElement={{
    tag: "oc-paper-card-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      size: { type: "String" },
      backgroundColor: { type: "String", attribute: "background-color" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./PaperCardV1.types.js";

  export let size: Props["size"] = "100";
  export let backgroundColor: Props["backgroundColor"] = undefined;
</script>

<div class="paper-card" style="--background-color:{backgroundColor}">
  {#if size === "100"}
    <div class="paper-card--size-100">
      <div class="paper-card--size-100__top">
        <slot />
      </div>
      <div class="paper-card--size-100__mid">
        <div class="paper-card--size-100__mid-cutout">
          <svg
            class="paper-card--size-100__mid-cutout--left"
            xmlns="http:/*                  */
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
            aria-hidden="true"
          >
            <path
              xmlns="http:/*                  */
              d="M16 0H0V2C5.52285 2 10 6.47715 10 12C10 17.5228 5.52285 22 0 22V24H16V0Z"
              fill="var(--background-color, #fff)"
            />
          </svg>
        </div>
        <div class="paper-card--size-100__mid-divider">
          <div class="paper-card--size-100__mid-divider-dottedLine"></div>
        </div>
        <div class="paper-card--size-100__mid-cutout">
          <svg
            class="paper-card--size-100__mid-cutout--right"
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http:/*                  */
            aria-hidden="true"
          >
            <path
              d="M16 0H0V24H16V22C10.4772 22 6 17.5228 6 12C6 6.47715 10.4772 2 16 2V0Z"
              fill="var(--background-color, #fff)"
            />
          </svg>
        </div>
      </div>
      <div class="paper-card--size-100__bottom">
        <slot name="footer" />
      </div>
    </div>
  {/if}

  {#if size === "50"}
    <div class="paper-card--size-50">
      <div class="paper-card--size-50__cutout-wrapper paper-card--size-50__cutout-wrapper--left">
        <div class="paper-card--size-50__fill paper-card--size-50__fill--top-left" />
        <div class="paper-card--size-50__cutout">
          <svg
            style="vertical-align: middle;"
            xmlns="http:/*                  */
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
            aria-hidden="true"
          >
            <path
              xmlns="http:/*                  */
              d="M16 0H0V2C5.52285 2 10 6.47715 10 12C10 17.5228 5.52285 22 0 22V24H16V0Z"
              fill="var(--background-color, #fff)"
            />
          </svg>
        </div>
        <div class="paper-card--size-50__fill paper-card--size-50__fill--bottom-left" />
      </div>

      <div class="paper-card--size-50__content">
        <slot />
      </div>
      <div class="paper-card--size-50__cutout-wrapper paper-card--size-50__cutout-wrapper--right">
        <div class="paper-card--size-50__fill paper-card--size-50__fill--top-right" />
        <div class="paper-card--size-50__cutout">
          <svg
            style="vertical-align: middle;"
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http:/*                  */
            aria-hidden="true"
          >
            <path
              d="M16 0H0V24H16V22C10.4772 22 6 17.5228 6 12C6 6.47715 10.4772 2 16 2V0Z"
              fill="var(--background-color, #fff)"
            />
          </svg>
        </div>
        <div class="paper-card--size-50__fill paper-card--size-50__fill--bottom-right" />
      </div>
    </div>
  {/if}
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    display: inline-block;
  }

  .paper-card {
    filter: tokens.$oc-component-paper-card-shadow;
    overflow-wrap: break-word;

    &--size-50 {
      display: flex;
      min-height: 48px;
    }

    &--size-100 {
      &__top {
        border-top-left-radius: tokens.$oc-component-paper-card-border-radius;
        border-top-right-radius: tokens.$oc-component-paper-card-border-radius;
        background-color: var(--background-color, tokens.$oc-component-paper-card-background-color);
        padding: tokens.$oc-component-paper-card-100-spacing
          tokens.$oc-component-paper-card-100-spacing tokens.$oc-component-paper-card-100-gap-y
          tokens.$oc-component-paper-card-100-spacing;
      }

      &__mid {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        /*              */
        margin-top: -1px;
        margin-bottom: -1px;

        &-cutout {
          height: tokens.$oc-component-paper-card-divider-area-height;
          &--left {
            vertical-align: middle;
            margin-right: -1px; /*              */
          }
          &--right {
            vertical-align: middle;
            margin-left: -1px; /*              */
          }
        }

        &-divider {
          width: 100%;
          height: tokens.$oc-component-paper-card-divider-area-height;
          background: var(--background-color, tokens.$oc-component-paper-card-background-color);
          justify-content: space-between;
          align-items: center;
          display: inline-flex;
        }

        &-divider-dottedLine {
          flex: 1 0 auto;
          height: 1px;
          border-top: tokens.$oc-component-paper-card-divider-width dashed
            tokens.$oc-component-paper-card-divider-color;
        }
      }

      &__bottom {
        background-color: var(--background-color, #fff);
        box-sizing: border-box;
        padding: tokens.$oc-component-paper-card-100-gap-y
          tokens.$oc-component-paper-card-100-spacing tokens.$oc-component-paper-card-100-spacing
          tokens.$oc-component-paper-card-100-spacing;
        border-bottom-left-radius: tokens.$oc-component-paper-card-border-radius;
        border-bottom-right-radius: tokens.$oc-component-paper-card-border-radius;
      }
    }

    &--size-50 {
      &__content {
        padding: tokens.$oc-component-paper-card-50-spacing-y 0
          tokens.$oc-component-paper-card-50-spacing-y 0;
        width: 100%;
        background: var(--background-color, tokens.$oc-component-paper-card-background-color);
      }

      &__cutout {
        margin-top: -1px;
        margin-bottom: -1px;

        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          &--left {
            margin-right: -1px; /*              */
          }

          &--right {
            margin-left: -1px; /*              */
          }
        }
      }
      &__fill {
        width: 16px;
        height: 100%;
        background: var(--background-color, tokens.$oc-component-paper-card-background-color);
        display: table-cell;
        vertical-align: middle;
        &--top-left {
          border-top-left-radius: tokens.$oc-component-paper-card-border-radius;
        }
        &--top-right {
          border-top-right-radius: tokens.$oc-component-paper-card-border-radius;
        }
        &--bottom-left {
          border-bottom-left-radius: tokens.$oc-component-paper-card-border-radius;
        }
        &--bottom-right {
          border-bottom-right-radius: tokens.$oc-component-paper-card-border-radius;
        }
      }
    }
  }
</style>
