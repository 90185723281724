<svelte:options
  customElement={{
    tag: "oc-text-area-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
      formAssociated: true,
    }),
    props: {
      type: { type: "String" },
      placeholder: { type: "String" },
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      label: { type: "String" },
      hint: { type: "String" },
      variant: { type: "String" },
      required: { type: "Boolean" },
      resizable: { type: "Boolean" },
      maxlength: { type: "Number" },
      hideCounter: { type: "Boolean", attribute: "hide-counter" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import CounterV1 from "../../../../common/components/CounterV1.svelte";
  import HintV1 from "../../../../common/components/HintV1.svelte";
  import { usePropertyChange } from "../../../../common/utils/usePropertyChange.js";
  import type { Props } from "./TextAreaV1.types";

  usePropertyChange();

  export let name: Props["name"] = undefined;
  export let value: Exclude<Props["value"], undefined> = "";
  export let hint: Props["hint"] = undefined;
  export let variant: Props["variant"] = "default";
  export let required: Props["required"] = false;
  export let disabled: Props["disabled"] = false;
  export let resizable: Props["resizable"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let placeholder: Props["placeholder"] = undefined;
  /*                                                                          */
  $: nonEmptyPlaceholder = placeholder || " ";

  export let maxlength: Props["maxlength"] = undefined;
  export let hideCounter: Props["hideCounter"] = false;

  /*  */
  /*                                     */
  /*  */
  export const hintId = "hint";

  $: showCounter = maxlength !== undefined && !hideCounter;

  export let internals: ElementInternals;

  export function resetForm() {
    /*          */
    value = "";
  }

  let textareaElement: HTMLTextAreaElement;

  $: if (textareaElement) {
    internals.setFormValue(value);
    if (!internals?.form?.noValidate) {
      internals.setValidity(
        textareaElement.validity,
        textareaElement.validationMessage,
        textareaElement,
      );
    }
  }
</script>

<div
  class="text-area"
  class:text-area--disabled={disabled}
  class:text-area--error={variant === "error"}
  class:text-area--success={variant === "success"}
  class:text-area--warning={variant === "warning"}
>
  <textarea
    bind:this={textareaElement}
    bind:value
    class="text-area__input"
    class:text-area__input--resizable={resizable}
    {disabled}
    id="input"
    {maxlength}
    {name}
    placeholder={nonEmptyPlaceholder}
    {required}
    aria-describedby={hint ? "hint" : undefined}
    spellcheck="false"
    autocorrect="off"
  />
  <label class="text-area__label" for="input" aria-label={ocAriaLabel}>
    <slot />
  </label>
</div>

{#if hint || showCounter}
  <div class="text-area__hint-and-counter">
    {#if hint}
      <HintV1 id={hintId} {hint} {disabled} />
    {/if}
    {#if showCounter}
      <CounterV1 counterValue={value} maxCounter={maxlength} {disabled} />
    {/if}
  </div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../../common/scss/mixins/mixins.scss";

  :host {
    @include mixins.no-tap-highlight();
  }

  .text-area {
    position: relative;

    &__input {
      /*                                                       */
      resize: unset;
      display: block;

      box-sizing: border-box;
      width: 100%;

      height: 84px;
      min-height: 84px;

      padding: tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;

      background: tokens.$oc-component-form-background-color;
      outline: 1px solid tokens.$oc-component-form-default-border-color;
      border: none;
      border-radius: 8px;

      font: tokens.$oc-component-form-field-input-font;
      color: tokens.$oc-component-form-field-input-color;

      &--resizable {
        resize: vertical;
        outline-offset: 4px;
      }

      &:hover:not(:disabled) {
        outline-width: 2px;
      }

      &:focus {
        outline-color: tokens.$oc-component-form-default-border-color-focus;
        outline-width: 2px;
      }

      &::placeholder {
        font: tokens.$oc-component-form-field-placeholder-font;
        color: tokens.$oc-component-form-field-placeholder-color;
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }

    &__label {
      position: absolute;
      top: tokens.$oc-component-form-field-spacing-y;
      left: tokens.$oc-semantic-spacing-50;
      padding: 0 tokens.$oc-semantic-spacing-25;
      max-width: 100%;
      box-sizing: border-box;

      transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      cursor: text;
      font: tokens.$oc-component-form-label-font;
      background-color: tokens.$oc-component-form-background-color;
      border-radius: tokens.$oc-component-form-field-label-border-radius;
      color: tokens.$oc-component-form-default-label-color;
    }

    &__hint-and-counter {
      display: grid;
      grid-column-gap: 16px;
      margin-top: tokens.$oc-component-form-hint-gap-y;
      width: 100%;

      > .counter {
        justify-self: end;
        grid-column-start: 2;
      }
    }

    &--error {
      .text-area__input {
        outline-color: tokens.$oc-component-form-error-border-color;
      }

      .text-area__label {
        color: tokens.$oc-component-form-error-label-color;
      }
    }

    &--warning {
      .text-area__input {
        outline-color: tokens.$oc-component-form-warning-border-color;
      }
    }

    &--success {
      .text-area__input {
        outline-color: tokens.$oc-component-form-success-border-color;
      }

      .text-area__label {
        color: tokens.$oc-component-form-success-label-color;
      }
    }

    &--disabled {
      .text-area__input {
        outline-color: tokens.$oc-component-form-disabled-border-color;
        background-color: tokens.$oc-component-form-disabled-background-color;
        color: tokens.$oc-component-form-disabled-input-color;
      }

      .text-area__label {
        background-color: tokens.$oc-component-form-disabled-background-color;
        color: tokens.$oc-component-form-disabled-label-color;
      }
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
      &::placeholder {
        opacity: 1;
      }

      ~ .text-area__label {
        top: -0.5rem;
        left: tokens.$oc-component-form-field-floating-label-spacing-left;
        padding: 0 tokens.$oc-component-form-field-floating-label-inner-spacing-x;
        font: tokens.$oc-component-form-field-floating-label-font;
      }
    }
  }
</style>
