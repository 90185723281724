/*                                                         */
/*         */
/*                                 */
import { get_current_component } from "svelte/internal";

/**
 *
 *
 */

export function useFullPathHrefGetter(): void {
  const component = get_current_component().$$;
  const { host } = component.root as { host: HTMLElement };

  Object.defineProperty(host, "href", {
    get() {
      const href = component.ctx[component.props.href];
      if (!href) return href;
      const a = document.createElement("a");
      a.href = href; /*       */
      return a.href; /*            */
    },
    set(href: string) {
      component.ctx[component.props.href] = href;
    },
  });
}
