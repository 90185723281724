<svelte:options
  customElement={{
    tag: "oc-icon-v1",
    props: {
      color: { type: "String" },
      size: { type: "String", reflect: true },
      type: { type: "String" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./IconV1.types";

  export let type: Props["type"];
  export let size: Props["size"] = "100";
  export let color: Props["color"] = undefined;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  $: iconIdentifier = `pl_icon_${type}${size === "50" ? "50" : ""}`;
  $: href = `/assets-static/icons/${iconIdentifier}.svg#${iconIdentifier}`;
  $: isAriaHidden = !ocAriaLabel;
</script>

{#if type}
  <svg class="icon" style:fill={color} aria-hidden={isAriaHidden} aria-label={ocAriaLabel}>
    <use {href} />
  </svg>
{/if}

<style lang="scss" global>
  @use "../../../common/scss/mixins/no-tap-highlight.scss" as mixins;
  :host {
    @include mixins.no-tap-highlight();
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  :host([size="50"]) {
    width: 12px;
    height: 12px;
  }

  :host([size="100"]) {
    width: 24px;
    height: 24px;
  }

  .icon {
    vertical-align: top;
    height: 100%;
    width: 100%;
  }
</style>
