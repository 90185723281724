<svelte:options
  customElement={{
    tag: "oc-radio-button-v1",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { usePropertyChange } from "../../../../common/utils/usePropertyChange";
  import { useSingleSelection } from "../../../../common/utils/useSingleSelection";
  import type { Props } from "./RadioButtonV1.types";
  import { implicitSubmitHandler } from "../../../../common/utils/form/implicitSubmitHandler";

  usePropertyChange();

  export let name: Props["name"] = undefined;
  export let value: Exclude<Props["value"], undefined> = "on";
  export let checked: Exclude<Props["checked"], undefined> = false;
  export let variant: Exclude<Props["variant"], undefined> = "default";
  export let disabled: Props["disabled"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;
  export let internals: ElementInternals;

  export let host: HTMLElement;

  export function resetForm() {
    /*                            */
    checked = host.hasAttribute("checked");
  }

  $: internals?.setFormValue(checked ? value : null);
  $: host.ariaChecked = checked.toString();

  const { destroy, mount, update } = useSingleSelection(host);
  onMount(mount);
  onDestroy(destroy);
  $: update({ name, checked });

  /*                              */
  host.addEventListener("keydown", implicitSubmitHandler(internals));
</script>

<label class="radio" class:radio--error={variant === "error"} aria-label={ocAriaLabel}>
  <span class="radio__input" />
  <slot />
</label>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../../common/scss/mixins/mixins.scss";

  :host {
    @include mixins.no-tap-highlight();
    display: block;
    padding-left: unset !important; /*                           */
    outline: none;
  }

  .radio {
    box-sizing: border-box;
    position: relative;
    display: block;
    padding-left: calc(
      tokens.$oc-component-form-radio-button-container-size +
        tokens.$oc-component-form-radio-button-gap-x
    );

    font: tokens.$oc-component-form-field-input-font;
    color: tokens.$oc-component-form-default-label-color;

    &__input {
      position: absolute;
      top: 0;
      left: 0;

      width: tokens.$oc-component-form-radio-button-container-size;
      height: tokens.$oc-component-form-radio-button-container-size;

      outline: 1px solid tokens.$oc-component-form-default-border-color;
      border-radius: 50%;

      background-color: tokens.$oc-component-form-background-color;
    }

    &--error {
      color: tokens.$oc-component-form-error-label-color;

      .radio__input {
        outline-color: tokens.$oc-component-form-error-border-color;
      }
    }
  }

  :host([aria-checked="true"]) {
    /*                         */
    .radio__input:before {
      $center-dot: 7px;

      content: "";
      display: block;
      position: absolute;
      left: $center-dot;
      top: $center-dot;
      bottom: $center-dot;
      right: $center-dot;

      border-radius: 50%;
      background: tokens.$oc-component-form-default-icon-color;
    }

    .radio--error {
      .radio__input:before {
        background: tokens.$oc-component-form-error-icon-color;
      }
    }
  }

  :host(:active:enabled) {
    :not(.radio--error) {
      .radio__input {
        outline-color: tokens.$oc-component-form-default-border-color-active;
        outline-width: 2px;
      }
    }
  }

  :host(:active:enabled) {
    .radio--error {
      .radio__input {
        outline-width: 2px;
      }
    }
  }

  @media (hover: hover) {
    :host(:hover:enabled) {
      .radio {
        cursor: pointer;
      }

      .radio__input {
        outline-width: 2px;
      }
    }
  }

  :host(:focus) {
    .radio__input:after {
      @include mixins.focus-common-styles();
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  @supports selector(*:focus-visible) {
    :host(:focus) {
      .radio__input:after {
        all: unset;
      }
    }

    :host(:focus-visible) {
      .radio__input:after {
        @include mixins.focus-common-styles();
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  :host(:disabled) {
    .radio {
      color: tokens.$oc-component-form-disabled-label-color;

      .radio__input {
        outline-color: tokens.$oc-component-form-disabled-border-color;
        background-color: tokens.$oc-component-form-disabled-background-color;

        &:before {
          background: tokens.$oc-component-form-disabled-icon-color;
        }
      }
    }
  }
</style>
