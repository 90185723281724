/**
 *
 *
 *
 *
 */
export function calcScrollLeft(targetItem: HTMLElement, gap: number, cut: number): number {
  const { width } = targetItem.getBoundingClientRect();
  return targetItem.offsetLeft - 2 * gap - (width * cut) / 2;
}
