<script lang="ts">
  export let hidden: boolean;
  export let direction: "left" | "right";
  export let arrowButtonPosition: string | undefined;
  export let onClick: () => void;

  let removed = hidden;

  let timer: number;
  $: {
    clearTimeout(timer);
    if (hidden) {
      timer = setTimeout(() => {
        removed = true;
      }, 1000) as unknown as number;
    } else {
      removed = false;
    }
  }
</script>

<oc-icon-button-v1
  class={`arrow-button arrow-button--${direction}`}
  class:arrow-button--hidden={hidden}
  class:arrow-button--removed={removed}
  style:--arrow-button-position={arrowButtonPosition}
  iconType={`arrow-${direction}`}
  on:click={onClick}
  aria-hidden="true"
  elevated
  tabindex="-1"
  disabled={hidden}
></oc-icon-button-v1>

<style lang="scss" global>
  @use "../../scss/mixins/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  /*          */
  .arrow-button {
    display: none;
  }

  /*                                                 */
  @media (hover: hover) and (pointer: fine) {
    .arrow-button {
      display: block;
      position: absolute;
      top: calc(
        var(--arrow-button-position, 50%) - tokens.$oc-component-icon-button-50-background-size / 2
      );

      &--hidden {
        opacity: 0;
      }

      &--removed {
        display: none;
      }

      &--left {
        left: 8px;
      }

      &--right {
        right: 8px;
      }
    }
  }
</style>
