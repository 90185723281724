<svelte:options
  customElement={{
    tag: "oc-checkbox-v1",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { usePropertyChange } from "../../../../common/utils/usePropertyChange";
  import { useMultiSelection } from "../../../../common/utils/useMultiSelection";
  import type { Props } from "./CheckboxV1.types";
  import { implicitSubmitHandler } from "../../../../common/utils/form/implicitSubmitHandler";

  usePropertyChange();

  export let name: Props["name"] = undefined;

  export let value: Exclude<Props["value"], undefined> = "on";

  export let checked: Exclude<Props["checked"], undefined> = false;

  /**
 *
 *
 *
 */
  export let variant: Props["variant"] = "default";

  /**
 *
 *
 *
 */
  export let disabled: Props["disabled"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let internals: ElementInternals;

  export let host: HTMLElement;

  export function resetForm() {
    /*                            */
    checked = host.hasAttribute("checked");
  }

  $: internals?.setFormValue(checked ? value : null);
  $: host.ariaChecked = checked.toString();

  const { mount, destroy } = useMultiSelection(host);
  onMount(mount);
  onDestroy(destroy);

  /*                              */
  host.addEventListener("keydown", implicitSubmitHandler(internals));
</script>

<label class="checkbox" class:checkbox--error={variant === "error"} aria-label={ocAriaLabel}>
  <span class="checkbox__input" />
  <slot />
</label>

<style lang="scss" global>
  @use "../../../../common/scss/mixins/mixins.scss";
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
    outline: none;
  }

  .checkbox {
    box-sizing: border-box;
    position: relative;
    display: block;
    padding-left: calc(
      tokens.$oc-component-form-checkbox-container-size + tokens.$oc-component-form-checkbox-gap-x
    );

    color: tokens.$oc-component-form-default-label-color;
    font: tokens.$oc-component-form-field-input-font;

    &__input {
      position: absolute;
      top: 0;
      left: 0;

      width: tokens.$oc-component-form-checkbox-container-size;
      height: tokens.$oc-component-form-checkbox-container-size;

      outline: 1px solid tokens.$oc-component-form-default-border-color;
      border-radius: tokens.$oc-component-form-checkbox-border-radius;

      background-color: tokens.$oc-component-form-background-color;
    }

    &--error {
      color: tokens.$oc-component-form-error-label-color;

      .checkbox__input {
        outline-color: tokens.$oc-component-form-error-border-color;
      }
    }
  }

  :host([aria-checked="true"]) {
    /*                         */
    .checkbox__input:before {
      $center-icon: calc(50% - tokens.$oc-component-form-checkbox-icon-size / 2);

      content: "";
      display: block;
      position: absolute;
      left: $center-icon;
      top: $center-icon;
      bottom: $center-icon;
      right: $center-icon;

      mask-image: url("/assets-static/icons/pl_icon_check50.svg");
      background-color: tokens.$oc-component-form-default-icon-color;
    }

    .checkbox--error {
      .checkbox__input:before {
        background: tokens.$oc-component-form-error-icon-color;
      }
    }
  }

  :host(:active:enabled) {
    :not(.checkbox--error) {
      .checkbox__input {
        outline-color: tokens.$oc-component-form-default-border-color-active;
        outline-width: 2px;
      }
    }
  }

  :host(:active:enabled) {
    .checkbox--error {
      .checkbox__input {
        outline-width: 2px;
      }
    }
  }

  @media (hover: hover) {
    :host(:hover:enabled) {
      .checkbox {
        cursor: pointer;
      }

      .checkbox__input {
        outline-width: 2px;
      }
    }
  }

  :host(:focus) {
    .checkbox__input:after {
      $offset: calc(-1 * (tokens.$oc-semantic-focus-outline-offset));
      content: "";
      display: block;
      position: absolute;
      left: $offset;
      top: $offset;
      bottom: $offset;
      right: $offset;
      outline: tokens.$oc-semantic-focus-outline-color tokens.$oc-semantic-focus-outline-style
        tokens.$oc-semantic-focus-outline-width;
      border-radius: tokens.$oc-component-form-checkbox-border-radius;
    }
  }

  @supports selector(*:focus-visible) {
    :host(:focus) {
      .checkbox__input:after {
        all: unset;
      }
    }

    :host(:focus-visible) {
      .checkbox__input:after {
        $offset: calc(-1 * (tokens.$oc-semantic-focus-outline-offset));
        content: "";
        display: block;
        position: absolute;
        left: $offset;
        top: $offset;
        bottom: $offset;
        right: $offset;
        outline: tokens.$oc-semantic-focus-outline-color tokens.$oc-semantic-focus-outline-style
          tokens.$oc-semantic-focus-outline-width;
        border-radius: tokens.$oc-component-form-checkbox-border-radius;
      }
    }
  }

  :host(:disabled) {
    .checkbox {
      color: tokens.$oc-component-form-disabled-label-color;

      .checkbox__input {
        outline-color: tokens.$oc-component-form-disabled-border-color;
        background-color: tokens.$oc-component-form-disabled-background-color;

        &:before {
          background: tokens.$oc-component-form-disabled-icon-color;
        }
      }
    }
  }
</style>
