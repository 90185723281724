<svelte:options
  customElement={{
    tag: "oc-selection-tile-v1",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      width: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      color: { type: "String" },
      fillParent: { type: "Boolean", attribute: "fill-parent" },
      singleSelection: { type: "Boolean", attribute: "single-selection" },
      imgSrc: { type: "String", attribute: "img-src" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { onDestroy } from "svelte";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import { useSingleSelection } from "../../../common/utils/useSingleSelection";
  import { useMultiSelection } from "../../../common/utils/useMultiSelection";
  import type { Props } from "./SelectionTileV1.types.js";

  usePropertyChange();

  export let variant: Props["variant"] = "text";
  export let disabled: Props["disabled"] = false;
  export let checked: Exclude<Props["checked"], undefined> = false;
  export let value: Exclude<Props["value"], undefined> = "on";
  export let name: Props["name"] = undefined;
  export let fillParent: Props["fillParent"] = false;
  export let color: Props["color"] = undefined;
  export let imgSrc: Props["imgSrc"] = undefined;
  export let singleSelection: Props["singleSelection"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let host: HTMLOcSelectionTileV1Element;

  export let internals: ElementInternals;

  $: internals?.setFormValue(checked ? value : null);
  $: host.ariaChecked = checked.toString();

  const multiSel = useMultiSelection(host);
  const singleSel = useSingleSelection(host);

  $: if (singleSelection) {
    multiSel.destroy();
    singleSel.mount();
    onDestroy(singleSel.destroy);
  } else {
    singleSel.destroy();
    multiSel.mount();
    onDestroy(multiSel.destroy);
  }

  $: if (singleSelection) {
    singleSel.update({ name, checked });
  }
</script>

<label
  class="selection-tile {`selection-tile--variant-${variant}`} "
  class:selection-tile--checked={checked}
  class:selection-tile--disabled={disabled}
  class:selection-tile--fill-parent={fillParent}
  class:selection-tile--variant-container--fill-parent={variant === "container" && fillParent}
  style="--color:{color}; --imgSrc:url({imgSrc});"
  aria-label={ocAriaLabel}
>
  {#if variant === "text" || variant === "container"}
    <slot />
  {/if}
</label>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  :host {
    @include mixins.no-tap-highlight();
    outline: none;
  }

  /*                    */

  /**
 *
 *
 */

  /*                                                              */
  :host(:focus) {
    .selection-tile::before {
      cursor: pointer;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;

      @include mixins.focus-common-styles(
        tokens.$oc-component-selection-tile-roundrect-border-radius
      );
    }
  }

  :host([variant="image"]:focus),
  :host([variant="color"]:focus) {
    .selection-tile::before {
      @include mixins.focus-common-styles();
    }
  }

  /*                                                                                                 */
  @supports selector(*:focus-visible) {
    :host(:focus) {
      .selection-tile::before {
        /*                                    */
        all: unset;
      }
    }

    :host(:focus-visible) {
      .selection-tile::before {
        cursor: pointer;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;

        @include mixins.focus-common-styles(
          tokens.$oc-component-selection-tile-roundrect-border-radius
        );
      }
    }

    :host([variant="image"]:focus-visible),
    :host([variant="color"]:focus-visible) {
      .selection-tile::before {
        @include mixins.focus-common-styles();
      }
    }
  }

  @mixin selection-tile-rounded {
    padding: 0;
    width: tokens.$oc-component-selection-tile-round-size;
    height: tokens.$oc-component-selection-tile-round-size;
    border-radius: 50%;
  }

  @mixin pseudo-element() {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  .selection-tile {
    /*     */
    position: relative;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    box-sizing: border-box;
    cursor: pointer;

    /*    */
    border-radius: tokens.$oc-component-selection-tile-roundrect-border-radius;
    background: tokens.$oc-component-selection-tile-background-color;
    outline: tokens.$oc-component-selection-tile-border-color solid
      tokens.$oc-component-selection-tile-border-width;
    color: tokens.$oc-component-selection-tile-text-color;

    &--variant-text {
      font: tokens.$oc-component-selection-tile-text-font;
    }

    &--variant-color {
      @include selection-tile-rounded();
      background-color: var(--color);

      height: tokens.$oc-component-selection-tile-round-size;
      width: tokens.$oc-component-selection-tile-round-size;
      padding: unset;
    }

    &--variant-image {
      @include selection-tile-rounded();
      background-image: var(--imgSrc);
      background-position: center;
      background-size: 100%;

      height: tokens.$oc-component-selection-tile-round-size;
      width: tokens.$oc-component-selection-tile-round-size;
      padding: unset;
    }

    &--variant-container {
      padding: 0;
    }

    &--fill-parent {
      width: 100%;
    }

    @media (hover: hover) {
      &:hover:not(.selection-tile--disabled):not(.selection-tile--checked) {
        outline: tokens.$oc-component-selection-tile-border-color-hover solid
          tokens.$oc-component-selection-tile-border-width-hover;
      }
    }

    @media (hover: hover) {
      &:hover:not(.selection-tile--disabled):not(.selection-tile--checked)::after {
        @include pseudo-element();

        outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
          tokens.$oc-component-selection-tile-inner-border-width-hover;
        outline-offset: -1px;
      }
    }

    &:active:not(.selection-tile--checked):not(.selection-tile--disabled) {
      outline: tokens.$oc-component-selection-tile-border-color-active solid
        tokens.$oc-component-selection-tile-border-width-active;
    }

    &:active:not(.selection-tile--checked):not(.selection-tile--disabled)::after {
      @include pseudo-element();

      outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
        tokens.$oc-component-selection-tile-inner-border-width-active;
      outline-offset: -1px;
    }

    &--checked {
      outline: tokens.$oc-component-selection-tile-border-color-selected solid
        tokens.$oc-component-selection-tile-border-width-selected;

      @media (hover: hover) {
        &:hover:not(.selection-tile--disabled) {
          outline: tokens.$oc-component-selection-tile-border-color-selected-hover solid
            tokens.$oc-component-selection-tile-border-width-selected-hover;
        }
      }

      &:active:not(.selection-tile--disabled) {
        outline: tokens.$oc-component-selection-tile-border-color-selected-active solid
          tokens.$oc-component-selection-tile-border-width-selected-active;
      }
    }

    &--checked::after {
      @include pseudo-element();

      outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
        tokens.$oc-component-selection-tile-inner-border-width-selected;
      outline-offset: -2px;

      @media (hover: hover) {
        &:hover::after {
          outline: tokens.$oc-component-selection-tile-inner-border-color-selected solid
            tokens.$oc-component-selection-tile-inner-border-width-selected;
          outline-offset: -1px;
        }
      }

      &:active::after {
        outline: tokens.$oc-component-selection-tile-border-color-selected-active solid
          tokens.$oc-component-selection-tile-inner-border-width-selected;
        outline-offset: -1px;
      }
    }

    &--disabled {
      outline: none;
    }

    /*                                           */
    &--disabled::after {
      @include pseudo-element();
      cursor: default;

      background-color: tokens.$oc-component-selection-tile-disabled-overlay;
      outline: tokens.$oc-component-selection-tile-disabled-border-color solid
        tokens.$oc-component-selection-tile-disabled-border-width;
      outline-offset: 0;
    }
  }
</style>
