import {
  writable,
  type Invalidator,
  type Readable,
  type Subscriber,
  type Unsubscriber,
  type Writable,
} from "svelte/store";

export type TooltipVerticalPosition = "top" | "bottom";
export type TooltipHorizontalPosition = "left" | "right" | "initial";

export interface TooltipPositionState {
  horizontal: TooltipHorizontalPosition;
  vertical: TooltipVerticalPosition;
  positioning: boolean;
}

export class TooltipPosition implements Readable<TooltipPositionState> {
  public constructor() {
    this.state = writable({
      horizontal: "initial",
      vertical: "bottom",
      positioning: false,
    });
    this.lock = null;
  }

  public reposition(elem: HTMLElement, preference?: TooltipVerticalPosition): void {
    const prevLock = this.lock ?? Promise.resolve();
    this.lock = new Promise((res) => {
      /*                                                                     */
      prevLock.then(() => this.unsafeReposition(elem, preference)).then(res);
    });
  }

  public subscribe(
    run: Subscriber<TooltipPositionState>,
    invalidate?: Invalidator<TooltipPositionState> | undefined,
  ): Unsubscriber {
    return this.state.subscribe(run, invalidate);
  }

  private readonly POSITION_PADDING = 16;

  private state: Writable<TooltipPositionState>;

  private lock: Promise<void> | null;

  /*                                                                                                */
  private async unsafeReposition(
    elem: HTMLElement,
    preference?: TooltipVerticalPosition,
  ): Promise<void> {
    this.reset(preference);
    /*                          */
    await new Promise((res) => {
      setTimeout(res);
    });
    this.calcPosition(elem, preference);
  }

  private reset(preference?: TooltipVerticalPosition): void {
    this.state.set({
      horizontal: "initial",
      vertical: preference ?? "bottom",
      positioning: true,
    });
  }

  private calcPosition(elem?: HTMLElement, preference?: TooltipVerticalPosition): void {
    if (!elem) return;
    const { left, right, top, bottom } = elem.getBoundingClientRect();

    const update: Partial<TooltipPositionState> = {
      positioning: false,
    };

    /*                                                             */
    const topOverflow = top < 0 || top > window.innerHeight;
    /*                                                                */
    const bottomOverflow = bottom < 0 || bottom > window.innerHeight;

    switch (preference) {
      case "top":
        if (topOverflow && !bottomOverflow) {
          update.vertical = "bottom";
        } else {
          update.vertical = "top";
        }
        break;
      case "bottom":
      default:
        if (bottomOverflow && !topOverflow) {
          update.vertical = "top";
        } else {
          update.vertical = "bottom";
        }
    }

    /*                                                               */
    const rightOverflow = right < 0 || right > window.innerWidth - this.POSITION_PADDING;
    /*                                                              */
    const leftOverflow = left < 0 || left > window.innerWidth - this.POSITION_PADDING;

    if (rightOverflow && !leftOverflow) {
      /*                                       */
      update.horizontal = "right";
    } else if (leftOverflow && !rightOverflow) {
      /*                                      */
      update.horizontal = "left";
    }
    /*                               */
    else {
      update.horizontal = "initial";
    }

    this.state.update((state) => ({ ...state, ...update }));
  }
}

export function useTooltipPosition(): TooltipPosition {
  return new TooltipPosition();
}
