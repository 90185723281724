import { createEventDispatcher, tick } from "svelte";
/*                                                         */
/*         */
/*                                 */
import { get_current_component } from "svelte/internal";

/**
 *
 *
 *
 *
 *
 */
export type PropertyChangeDetail<Props = Record<string, unknown>> = {
  [P in keyof Props]?: {
    /*                               */
    previous: Props[P];
    /*                             */
    current: Props[P];
  };
};

type AnyPropertyChangeDetail = PropertyChangeDetail<Record<string, unknown>>;

export type PropertyChangeEvent<Props = Record<string, unknown>> = CustomEvent<
  PropertyChangeDetail<Props>
>;

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function usePropertyChange(): void {
  const dispatch = createEventDispatcher<{ "oc-property-change": AnyPropertyChangeDetail }>();

  const propertyIgnoreList = ["aria", "host", "internals"];

  /*                                                      */
  let initialized = false;

  /*                                                                       */
  const lastState: Record<string, unknown> = {};
  const component = get_current_component();

  component.$$.after_update.push(async () => {
    const { props, ctx } = component.$$;

    /*                                  */
    const changes: AnyPropertyChangeDetail = {};
    Object.entries(props).forEach(([key, ctxIndex]) => {
      /*                */
      if (propertyIgnoreList.includes(key)) return;
      if (lastState[key] === ctx[ctxIndex]) return;

      /*                                    */
      changes[key] = { previous: lastState[key], current: ctx[ctxIndex] };
      lastState[key] = ctx[ctxIndex];
    });

    /*                                                                                      */
    /*              */
    if (!initialized) {
      initialized = true;
      return;
    }

    if (Object.keys(changes).length === 0) {
      return;
    }

    await tick();

    /*                                                          */
    dispatch("oc-property-change", changes);
  });
}
