<script lang="ts">
  export let disabled: boolean = false;

  export let counterValue: string | undefined = undefined;
  export let minCounter: number | undefined = undefined;
  export let maxCounter: number | undefined = undefined;
  $: minCounterText = minCounter === undefined ? "" : ` (min. ${minCounter})`;
  $: maxCounterText = maxCounter === undefined ? "" : ` (max. ${maxCounter})`;
  $: counterText = `${counterValue?.length ?? 0}${minCounterText}${maxCounterText}`;
</script>

<!-- aria-hidden -> because minlength/maxlength is set on input element -->
<small class="counter" class:counter--disabled={disabled} aria-hidden="true">{counterText}</small>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .counter {
    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-default-hint-color;

    &--disabled {
      color: tokens.$oc-component-form-disabled-hint-color;
    }
  }
</style>
