import {
  /*                                 */
  get_current_component,
  /*                                                         */
  /*         */
} from "svelte/internal";
import { type Writable, writable } from "svelte/store";

interface CustomElementChildren extends ChildNode {
  slot?: string;
}

/*                                                      */
/*                                                */
/*                                             */
function getCustomElementsSlots(element: HTMLElement): Record<string, boolean> {
  const result: Record<string, boolean> = {};
  Array.from(element.childNodes)
    .filter((node: ChildNode) => {
      /*                                  */
      return node.nodeType !== Node.COMMENT_NODE;
    })
    .forEach((node: CustomElementChildren) => {
      result[node.slot || "default"] = true;
    });
  return result;
}

export function useSlots(): Writable<Record<string, boolean>> {
  const component = get_current_component();

  const { host } = component.$$.root as { host: HTMLElement };

  const store = writable(getCustomElementsSlots(host));

  new MutationObserver(() => {
    store.set(getCustomElementsSlots(host));
  }).observe(host, { childList: true });

  return store;
}
