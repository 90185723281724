<svelte:options
  customElement={{
    tag: "oc-accordion-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      title: { type: "String" },
      expanded: { type: "Boolean" },
      hideDivider: { type: "Boolean", attribute: "hide-divider" },
    },
  }}
/>

<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./AccordionV1.types";
  import { onAnimationEnd } from "../../../common/utils/dom";

  usePropertyChange();

  export let title: Props["title"] = "";
  export let expanded: Props["expanded"] = false;
  export let hideDivider: Props["hideDivider"] = false;

  let contentElement: HTMLElement;
  let hidden = !expanded;

  const dispatch = createEventDispatcher<{
    "oc-open": undefined;
    "oc-close": undefined;
  }>();

  async function onClick() {
    if (expanded) {
      expanded = false;

      await tick();

      await onAnimationEnd(contentElement);

      hidden = true;
    } else {
      hidden = false;

      await tick();

      await onAnimationEnd(contentElement);

      expanded = true;
    }
  }

  $: dispatch(expanded ? "oc-open" : "oc-close");
</script>

<div class="panel">
  <span id="panel-title">
    <button
      on:click|preventDefault={onClick}
      class="oc-row-v1 oc-row-v1--interactive trigger"
      class:oc-row-v1--hide-divider={hideDivider}
      aria-expanded={expanded}
      aria-controls="content"
    >
      {title}
      <oc-icon-v1 type="arrow-down" />
    </button>
  </span>
  <div
    bind:this={contentElement}
    class="content"
    class:closed={!expanded}
    role="region"
    aria-labelledby="panel-title"
    id="content"
    {hidden}
  >
    <div>
      <slot />
    </div>
  </div>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../css-only-components/row/v1/RowV1.global" as row;

  .panel {
    .trigger {
      display: grid;
      font: tokens.$oc-component-accordion-label-font;
      color: tokens.$oc-component-accordion-label-color;
      grid-template-columns: 1fr 24px;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      align-items: center;

      grid-template-areas: "content icon";

      > * {
        grid-area: content;
      }

      > .title {
        padding-right: tokens.$oc-semantic-spacing-100;
      }

      & > oc-icon-v1 {
        fill: tokens.$oc-component-accordion-icon-color;
        grid-area: icon;
        transition: transform tokens.$oc-component-accordion-transition-duration
          tokens.$oc-component-accordion-transition-easing;
      }

      &[aria-expanded="true"] oc-icon-v1 {
        transform: rotate(-180deg);
      }
    }
  }

  .content {
    display: grid;
    grid-template-rows: 1fr;
    padding: tokens.$oc-component-accordion-gap-y 0 tokens.$oc-component-accordion-spacing-bottom 0;
    transition: grid-template-rows tokens.$oc-component-accordion-transition-duration
      tokens.$oc-component-accordion-transition-easing;
  }

  .content.closed {
    padding: 0;
    grid-template-rows: 0fr;
  }

  [hidden] {
    display: none !important;
  }

  .content > div {
    overflow: hidden;
  }
</style>
