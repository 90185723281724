/*                                                  */
import { closeContext, createContext } from "@otto-ec/tracking-bct";

export function createTrackingContext(sheetID: string, contentURL: string): void {
  createContext(sheetID, contentURL, { ts_OttoComponent: ["sheet@v1"] });
}

export function closeTrackingContext(): void {
  closeContext();
}
