<svelte:options
  customElement={{
    tag: "oc-tag-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      size: { type: "String" },
      sizeM: { type: "String", attribute: "size-m" },
      sizeL: { type: "String", attribute: "size-l" },
      variant: { type: "String" },
      showInfoIcon: { type: "Boolean", attribute: "show-info-icon" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import type { Props } from "./TagV1.types";

  export let size: Props["size"] = "100";
  export let sizeM: Props["size"] = undefined;
  export let sizeL: Props["size"] = undefined;
  export let variant: Props["variant"] = "info-primary";
  export let showInfoIcon: Props["showInfoIcon"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;
</script>

{#if showInfoIcon}
  <button
    class={`tag tag--size-${size} tag--size-m-${sizeM} tag--size-l-${sizeL} tag--variant-${variant} tag--interactive`}
  >
    <span class="tag__label" aria-label={ocAriaLabel}>
      <slot />
    </span>
    <oc-icon-v1
      class={`tag__icon tag__icon--size-${size}`}
      type="info-hint"
      size={"50"}
      color="currentColor"
    />
  </button>
{/if}

{#if !showInfoIcon}
  <span
    class={`tag tag--size-${size} tag--size-m-${sizeM} tag--size-l-${sizeL} tag--variant-${variant}`}
  >
    <span class="tag__label" aria-label={ocAriaLabel}>
      <slot />
    </span>
  </span>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  :host {
    @include mixins.no-tap-highlight();
    display: inline-block;
    max-width: 100%;
    outline: none;
  }

  .tag {
    align-items: center;
    border-radius: tokens.$oc-component-tag-border-radius;
    border: none;
    display: inline-flex;
    font: tokens.$oc-component-tag-100-font;
    height: fit-content;
    max-width: 100%;
    padding: tokens.$oc-component-tag-100-spacing-y tokens.$oc-component-tag-100-spacing-x;
    position: relative;

    &__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--size-25 {
      gap: tokens.$oc-component-tag-25-gap-x;
      font: tokens.$oc-component-tag-25-font;
      padding: tokens.$oc-component-tag-25-spacing-y tokens.$oc-component-tag-25-spacing-x;

      .tag__icon {
        width: tokens.$oc-component-tag-25-icon-size;
        height: tokens.$oc-component-tag-25-icon-size;
      }
    }

    &--size-50 {
      gap: tokens.$oc-component-tag-50-gap-x;
      font: tokens.$oc-component-tag-50-font;
      padding: tokens.$oc-component-tag-50-spacing-y tokens.$oc-component-tag-50-spacing-x;

      .tag__icon {
        width: tokens.$oc-component-tag-50-icon-size;
        height: tokens.$oc-component-tag-50-icon-size;
      }
    }

    &--size-100 {
      gap: tokens.$oc-component-tag-100-gap-x;
      font: tokens.$oc-component-tag-100-font;
      padding: tokens.$oc-component-tag-100-spacing-y tokens.$oc-component-tag-100-spacing-x;

      .tag__icon {
        width: tokens.$oc-component-tag-100-icon-size;
        height: tokens.$oc-component-tag-100-icon-size;
      }
    }

    /*                  */
    &--size-m-25 {
      @include mixins.breakpoint-from-m {
        gap: tokens.$oc-component-tag-25-gap-x;
        font: tokens.$oc-component-tag-25-font;
        padding: tokens.$oc-component-tag-25-spacing-y tokens.$oc-component-tag-25-spacing-x;

        .tag__icon {
          width: tokens.$oc-component-tag-25-icon-size;
          height: tokens.$oc-component-tag-25-icon-size;
        }
      }
    }

    &--size-m-50 {
      @include mixins.breakpoint-from-m {
        gap: tokens.$oc-component-tag-50-gap-x;
        font: tokens.$oc-component-tag-50-font;
        padding: tokens.$oc-component-tag-50-spacing-y tokens.$oc-component-tag-50-spacing-x;

        .tag__icon {
          width: tokens.$oc-component-tag-50-icon-size;
          height: tokens.$oc-component-tag-50-icon-size;
        }
      }
    }

    &--size-m-100 {
      @include mixins.breakpoint-from-m {
        gap: tokens.$oc-component-tag-100-gap-x;
        font: tokens.$oc-component-tag-100-font;
        padding: tokens.$oc-component-tag-100-spacing-y tokens.$oc-component-tag-100-spacing-x;

        .tag__icon {
          width: tokens.$oc-component-tag-100-icon-size;
          height: tokens.$oc-component-tag-100-icon-size;
        }
      }
    }

    /*                  */
    &--size-l-25 {
      @include mixins.breakpoint-from-l {
        gap: tokens.$oc-component-tag-25-gap-x;
        font: tokens.$oc-component-tag-25-font;
        padding: tokens.$oc-component-tag-25-spacing-y tokens.$oc-component-tag-25-spacing-x;

        .tag__icon {
          width: tokens.$oc-component-tag-25-icon-size;
          height: tokens.$oc-component-tag-25-icon-size;
        }
      }
    }

    &--size-l-50 {
      @include mixins.breakpoint-from-l {
        gap: tokens.$oc-component-tag-50-gap-x;
        font: tokens.$oc-component-tag-50-font;
        padding: tokens.$oc-component-tag-50-spacing-y tokens.$oc-component-tag-50-spacing-x;

        .tag__icon {
          width: tokens.$oc-component-tag-50-icon-size;
          height: tokens.$oc-component-tag-50-icon-size;
        }
      }
    }

    &--size-l-100 {
      @include mixins.breakpoint-from-l {
        gap: tokens.$oc-component-tag-100-gap-x;
        font: tokens.$oc-component-tag-100-font;
        padding: tokens.$oc-component-tag-100-spacing-y tokens.$oc-component-tag-100-spacing-x;

        .tag__icon {
          width: tokens.$oc-component-tag-100-icon-size;
          height: tokens.$oc-component-tag-100-icon-size;
        }
      }
    }

    &--variant-info-primary {
      background-color: tokens.$oc-component-tag-info-primary-background-color;
      color: tokens.$oc-component-tag-info-primary-text-color;
    }

    &--variant-sale {
      background-color: tokens.$oc-component-tag-sale-background-color;
      color: tokens.$oc-component-tag-sale-text-color;
    }

    &--variant-sold-out {
      background-color: tokens.$oc-component-tag-sold-out-background-color;
      color: tokens.$oc-component-tag-sold-out-text-color;
    }

    &--variant-error {
      background-color: tokens.$oc-component-tag-error-background-color;
      color: tokens.$oc-component-tag-error-text-color;
    }

    &--variant-success {
      background-color: tokens.$oc-component-tag-success-background-color;
      color: tokens.$oc-component-tag-success-text-color;
    }

    &--variant-warning {
      background-color: tokens.$oc-component-tag-warning-background-color;
      color: tokens.$oc-component-tag-warning-text-color;
    }

    &--variant-hint {
      background-color: tokens.$oc-component-tag-hint-background-color;
      color: tokens.$oc-component-tag-hint-text-color;
    }

    &--variant-info-secondary {
      background-color: tokens.$oc-component-tag-info-secondary-background-color;
      color: tokens.$oc-component-tag-info-secondary-text-color;
    }
  }

  /*                           */

  .tag--interactive {
    cursor: pointer;
  }

  .tag--interactive:focus,
  .tag--interactive:focus-visible {
    outline: none;
  }

  .tag--interactive:focus:after {
    @include mixins.focus-common-styles(0.01px);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @supports selector(*:focus-visible) {
    .tag--interactive:focus:after {
      all: unset;
    }

    .tag--interactive:focus-visible:after {
      @include mixins.focus-common-styles(0.01px);
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
</style>
