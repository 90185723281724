<svelte:options
  customElement={{
    tag: "oc-switch-v2",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true, delegateFocus: true }),
    props: {
      name: { type: "String", reflect: true },
      size: { type: "String" },
      labelPlacement: { type: "String", attribute: "label-placement" },
      disabled: { type: "Boolean", reflect: true },
      checked: { type: "Boolean" },
      loading: { type: "Boolean" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { fade } from "svelte/transition";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import { useSlots } from "../../../common/utils/useSlots.js";
  import { refireNonComposableNativeEvents } from "../../../common/utils/refireNonComposableNativeEvents.js";
  import { implicitSubmitHandler } from "../../../common/utils/form/implicitSubmitHandler";
  import type { Props } from "./SwitchV2.types.js";

  usePropertyChange();
  const slots = useSlots();

  export let name: Props["name"] = undefined;
  export let size: Props["size"] = "100";
  export let labelPlacement: Props["labelPlacement"] = undefined;
  export let disabled: Props["disabled"] = false;
  export let checked: Exclude<Props["checked"], undefined> = false;
  export let loading: Props["loading"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let value: Exclude<Props["value"], undefined> = "on";

  export let internals: ElementInternals;

  export let host: HTMLElement;

  $: internals?.setFormValue(checked ? value : null);

  const stopLabelClick = (event: Event) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }
    event.stopPropagation();
  };

  export function resetForm() {
    /*                            */
    checked = host.hasAttribute("checked");
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<label
  class="switch switch--size-{size}"
  class:switch--loading={loading}
  class:switch--checked={checked}
  class:switch--disabled={disabled}
  on:click={stopLabelClick}
>
  <!-- Left Label -->
  {#if $slots.default && labelPlacement === "left"}
    <span class="label"><slot /></span>
  {/if}
  <!-- Input display none -->
  <input
    class="input"
    type="checkbox"
    role="switch"
    aria-label={ocAriaLabel}
    aria-checked={checked}
    disabled={disabled || loading}
    bind:checked
    on:change={(e) => refireNonComposableNativeEvents(e, host)}
    on:input={(e) =>
      /*                                                 */ refireNonComposableNativeEvents(
        e,
        host,
      )}
    on:keydown={implicitSubmitHandler(internals)}
  />
  <span class="slider">
    <span class="handle" aria-hidden="true">
      {#if loading}
        <oc-spinner-v1 in:fade={{ duration: 200 }} class="spinner" variant={"default"} size={"50"}
        ></oc-spinner-v1>
      {:else if checked}
        <span class="checkmark"></span>
      {/if}
    </span>
  </span>
  <!-- Right Label -->
  {#if $slots.default && labelPlacement !== "left"}
    <span class="label"><slot /></span>
  {/if}
</label>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  $borderRadius: 16px;

  :host {
    display: inline-block;
    @include mixins.no-tap-highlight();
  }

  .label {
    font: tokens.$oc-component-switch-label-font;
  }

  .input {
    /*             */
    @include mixins.visually-hidden();
  }

  /*                                                       */
  @mixin switch-slider-color($bgColor) {
    .slider {
      background-color: $bgColor;
    }
  }

  @mixin switch-handle-position-checked($size) {
    .handle {
      @if $size == 50 {
        transform: translateX(10px);
      }
      @if $size == 100 {
        transform: translateX(14px);
      }
    }
  }

  @mixin switch-handle-color($bgColor) {
    .handle {
      background-color: $bgColor;
    }
  }
  @mixin switch-slider-size($size) {
    .slider {
      @if $size == 50 {
        height: tokens.$oc-component-switch-50-height;
        width: tokens.$oc-component-switch-50-width;
      }
      @if $size == 100 {
        height: tokens.$oc-component-switch-100-height;
        width: tokens.$oc-component-switch-100-width;
      }
    }
  }

  @mixin handle-size($size) {
    .handle {
      @if ($size == 50) {
        width: tokens.$oc-component-switch-50-handle-size;
        height: tokens.$oc-component-switch-50-handle-size;
        transform: translateX(-2px);

        .checkmark {
          width: tokens.$oc-component-switch-50-icon-size;
          height: tokens.$oc-component-switch-50-icon-size;
          top: 2px;
          left: 2px;
        }
        .spinner {
          position: absolute;
          width: 12px;
          height: 12px;
          top: 2px;
          left: 2px;
        }
      }
      @if $size == 100 {
        height: tokens.$oc-component-switch-100-handle-size;
        width: tokens.$oc-component-switch-100-handle-size;
        transform: translateX(-2px);

        .checkmark {
          width: tokens.$oc-component-switch-100-icon-size;
          height: tokens.$oc-component-switch-100-icon-size;
          top: 4px;
          left: 4px;
        }
        .spinner {
          position: absolute;
          width: 12px;
          height: 12px;
          top: 6px;
          left: 6px;
        }
      }
    }
  }

  .switch {
    display: flex;
    gap: tokens.$oc-component-switch-gap-x;
    align-items: center;
    outline: none;

    /*           */
    cursor: pointer;

    &.switch--loading {
      cursor: progress;
    }

    &.switch--disabled {
      cursor: default;
    }

    /*      */
    /*          */
    &:not(.switch--checked) {
      @include switch-slider-color(tokens.$oc-component-switch-default-background-color);

      &.switch--disabled {
        pointer-events: none;
        /*                                                                    */
        @include switch-slider-color(tokens.$oc-component-switch-disabled-background-color);
        @include switch-handle-color(tokens.$oc-component-switch-disabled-handle-color);

        .label {
          color: tokens.$oc-component-switch-disabled-label-color;
        }
      }
      /*                                  */
      &:not(.switch--disabled):not(.switch--loading) {
        &:hover {
          @include switch-slider-color(tokens.$oc-component-switch-default-background-color-hover);
        }

        &:active {
          @include switch-slider-color(tokens.$oc-component-switch-default-background-color-active);
        }
      }
    }

    /*      */
    &.switch--checked {
      @include switch-slider-color(tokens.$oc-component-switch-checked-background-color);

      &.switch--disabled {
        pointer-events: none;
        /*                                                                    */

        @include switch-slider-color(tokens.$oc-component-switch-disabled-background-color);
        @include switch-handle-color(tokens.$oc-component-switch-disabled-handle-color);

        .label {
          color: tokens.$oc-component-switch-disabled-label-color;
        }

        /*                               */
        .slider .handle {
          .checkmark {
            background-color: tokens.$oc-component-switch-disabled-icon-color;
          }
        }
      }
      /*                              */
      &:not(.switch--disabled):not(.switch--loading) {
        &:hover {
          @include switch-slider-color(tokens.$oc-component-switch-checked-background-color-hover);
        }

        &:active {
          @include switch-slider-color(tokens.$oc-component-switch-checked-background-color-active);
        }
      }
    }

    &--size-50 {
      @include switch-slider-size(50);
      @include handle-size(50);

      &.switch--checked {
        @include switch-handle-position-checked(50);
      }
    }

    &--size-100 {
      @include switch-slider-size(100);
      @include handle-size(100);

      &.switch--checked {
        @include switch-handle-position-checked(100);
      }
    }

    /*    */
    input:focus + .slider {
      @include mixins.focus-common-styles($borderRadius);
    }
    /*                                                                                                 */
    @supports selector(*:focus-visible) {
      input:focus + .slider {
        /*                                            */
        box-sizing: border-box;
      }

      input:focus-visible + .slider {
        @include mixins.focus-common-styles($borderRadius);
      }
    }

    /*     */
    .slider {
      display: block;
      transition:
        background-color tokens.$oc-component-switch-transition-duration,
        opacity 0.4s;
      border-radius: $borderRadius;
      flex: 0 0 auto;
      box-sizing: border-box;
      /*                                              */
      padding: 4px 6px;

      /*                   */
      .handle {
        position: relative;
        display: block;
        background-color: tokens.$oc-component-switch-default-handle-color;
        transition: transform tokens.$oc-component-switch-transition-duration
          tokens.$oc-component-switch-transition-easing;
        border-radius: 50%;

        .checkmark {
          position: absolute;
          mask-image: url("/assets-static/icons/pl_icon_check50.svg");
          background-color: tokens.$oc-component-switch-checked-icon-color;
        }
      }
    }
  }
</style>
