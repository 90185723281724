<svelte:options
  customElement={{
    tag: "oc-expander-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      variant: { type: "String" },
      cutoffHeight: { type: "Number", attribute: "cutoff-height" },
      expanded: { type: "Boolean" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      hideFade: { type: "Boolean", attribute: "hide-fade" },
    },
  }}
/>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./ExpanderV1.types";

  usePropertyChange();

  export let variant: Props["variant"] = "row";
  export let cutoffHeight: Props["cutoffHeight"] = 150;
  export let expanded: Props["expanded"] = false;
  export let hideFade: Props["expanded"] = false;
  export let ocAriaLabel: string;

  let clientHeight: number = 0;

  $: isCutOff = clientHeight > (cutoffHeight || 150);
  /**
 *
 *
 */
  $: displayFade = isCutOff && !expanded;

  const dispatch = createEventDispatcher<{
    "oc-open": undefined;
    "oc-close": undefined;
  }>();

  $: dispatch(expanded ? "oc-open" : "oc-close");

  function onClick(event: MouseEvent) {
    event.preventDefault();
    expanded = !expanded;
  }
</script>

<div class={`expander expander--variant-${variant}`}>
  <div class="expander__container">
    <section
      class="expander__content"
      class:expander__content--open={expanded}
      style="--cutoff-height:{cutoffHeight}px"
      id="content"
      aria-label={ocAriaLabel}
    >
      <div bind:clientHeight>
        <slot />
      </div>
    </section>
    {#if isCutOff}
      <div id="hint">
        Für Screenreader wird der gesamte Inhalt bereits angezeigt, für sehende Nutzer:innen wird
        der Inhalt ein- bzw. ausgeklappt.
      </div>
      <button
        on:click={onClick}
        class="oc-row-v1 oc-row-v1--hide-divider oc-row-v1--interactive trigger"
        class:oc-row-v1--hide-divider={variant === "text"}
        aria-expanded={expanded}
        aria-describedby="hint"
        aria-controls="content"
      >
        {#if displayFade && !hideFade}
          <div class="fade" />
        {/if}
        {#if expanded}
          <slot name="show-less-text">Weniger anzeigen</slot>
        {:else}
          <slot name="show-more-text">Mehr anzeigen</slot>
        {/if}
        <oc-icon-v1 type="arrow-down" />
      </button>
    {/if}
  </div>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../css-only-components/row/v1/RowV1.global" as row;
  @use "../../../common/scss/mixins/mixins.scss";

  .expander {
    display: flex;
    background-color: tokens.$oc-component-expander-background-color;

    .trigger {
      display: grid;
      font: tokens.$oc-component-expander-font;

      grid-template-rows: 1fr;
      column-gap: tokens.$oc-component-expander-gap-x;
      align-items: center;

      > slot {
        color: tokens.$oc-component-expander-text-color;
        grid-area: content;
      }

      & > oc-icon-v1 {
        fill: tokens.$oc-component-expander-icon-color;
        grid-area: icon;
        transition: transform tokens.$oc-component-expander-transition-duration
          tokens.$oc-component-expander-transition-easing;
      }

      &[aria-expanded="true"] oc-icon-v1 {
        transform: rotate(-180deg);
      }
    }

    &--variant-row {
      .trigger {
        grid-template-columns: 1fr 24px;
        grid-template-areas: "content icon";
      }
    }

    &--variant-text {
      .trigger {
        grid-template-columns: 24px 1fr;
        grid-template-areas: "icon content";
      }
    }
  }

  .expander__container {
    width: 100%;
  }

  .expander__content {
    position: relative;
    transition: max-height tokens.$oc-component-expander-transition-duration
      tokens.$oc-component-expander-transition-easing;
    max-height: var(--cutoff-height);
    overflow: hidden;
  }

  .expander__content--open {
    max-height: 100% !important;
    overflow: visible;
  }
  #hint {
    @include mixins.visually-hidden();
  }

  .fade {
    background: tokens.$oc-component-expander-fade-color;
    bottom: 48px;
    cursor: pointer;
    height: tokens.$oc-component-expander-fade-height;
    left: 0;
    position: absolute;
    width: 100%;
  }
</style>
