<svelte:options
  customElement={{
    tag: "oc-tooltip-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
    }),
    props: {
      position: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import { useTooltipPosition } from "../../../common/utils/useTooltipPosition";
  import type { Props } from "./TooltipV1.types";

  export let position: Props["position"] = "bottom";

  let element: HTMLElement;

  const pos = useTooltipPosition();
  let tooltipOpen = false;

  $: pos.reposition(element, position);

  function open() {
    tooltipOpen = true;
    /*                                                                                     */
    setTimeout(() => pos.reposition(element, position));
  }

  function close() {
    tooltipOpen = false;
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- eslint-disable-next-line svelte/valid-compile -->
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<div
  class="tooltip-box"
  data-position-v={$pos.vertical}
  data-position-h={$pos.horizontal}
  on:mouseover={open}
  on:mouseleave={close}
  on:focusin={open}
  on:focusout={close}
  on:keydown={({ code }) => {
    if (tooltipOpen && code === "Escape") {
      close();
    }
  }}
>
  <slot />
  <div class="tooltip-anchor" class:hidden={!tooltipOpen} class:positioning={$pos.positioning}>
    <span class="arrow"></span>
    <div class="body" bind:this={element}>
      <slot name="tooltip-content" />
    </div>
  </div>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  :host {
    display: inline-block;
    line-height: 0;
  }

  $verticalOffset: calc(
    tokens.$oc-component-tooltip-context-spacing + tokens.$oc-component-tooltip-arrow-height
  );

  .tooltip-box {
    line-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &[data-position-v="bottom"] {
      flex-direction: column;

      .tooltip-anchor {
        justify-content: flex-end;
      }

      .arrow {
        transform: translateY($verticalOffset);
      }

      .body {
        transform: translateY(calc(100% + $verticalOffset));
      }
    }

    &[data-position-v="top"] {
      flex-direction: column-reverse;

      .tooltip-anchor {
        justify-content: flex-start;
      }

      .arrow {
        transform: rotate(180deg) translateY($verticalOffset);
      }

      .body {
        transform: translateY(calc(-100% - $verticalOffset));
      }
    }

    &[data-position-h="left"] {
      .body {
        left: tokens.$oc-component-tooltip-frame-spacing;
      }
    }

    &[data-position-h="right"] {
      .body {
        right: tokens.$oc-component-tooltip-frame-spacing;
      }
    }
  }

  .tooltip-anchor {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    transition: opacity tokens.$oc-component-tooltip-show-hide-transition-duration
      tokens.$oc-component-tooltip-show-hide-transition-easing
      tokens.$oc-component-tooltip-show-delay;

    &.hidden {
      transition:
        opacity tokens.$oc-component-tooltip-show-hide-transition-duration
          tokens.$oc-component-tooltip-show-hide-transition-easing
          tokens.$oc-component-tooltip-hide-delay,
        visibility 0ms tokens.$oc-component-tooltip-show-hide-transition-duration +
          tokens.$oc-component-tooltip-hide-delay;
      visibility: hidden;
      opacity: 0;
    }

    &.positioning {
      opacity: 0;
    }
  }

  .arrow {
    $seamFixHeight: 1px;

    width: calc(tokens.$oc-component-tooltip-arrow-width + $seamFixHeight * 2);
    height: calc(tokens.$oc-component-tooltip-arrow-height + $seamFixHeight);
    background-color: tokens.$oc-component-tooltip-background-color;
    margin-bottom: -$seamFixHeight;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    transform-origin: center;
    position: absolute;
    z-index: 1;
  }

  .body {
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    max-width: min(
      tokens.$oc-component-tooltip-max-width,
      calc(100vw - 2 * tokens.$oc-component-tooltip-frame-spacing)
    );
    width: max-content;
    background-color: tokens.$oc-component-tooltip-background-color;
    color: tokens.$oc-component-tooltip-copy-color;
    border-radius: tokens.$oc-component-tooltip-border-radius;
    padding: tokens.$oc-component-tooltip-spacing-y tokens.$oc-component-tooltip-spacing-x;
    font: tokens.$oc-component-tooltip-copy-font;
  }
</style>
