<svelte:options
  customElement={{
    tag: "oc-link-v2",
    shadow: "none",
    /*                       */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      variant: { type: "String" },
      size: { type: "String", reflect: true },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      asButton: { type: "Boolean", attribute: "as-button" },
      target: { type: "String" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import { useFullPathHrefGetter } from "../../../common/utils/useFullPathHrefGetter";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./LinkV2.types.js";

  usePropertyChange();
  useFullPathHrefGetter();

  export let variant: Props["variant"] = "primary";
  export let size: Props["size"] = "100";
  export let href: Props["href"] = undefined;
  export let base64Href: Props["base64Href"] = undefined;
  export let asButton: Props["asButton"] = false;
  export let target: Props["target"] = undefined;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;
</script>

<InteractiveElement
  {asButton}
  bind:href
  {base64Href}
  {target}
  class={`link link--${variant}`}
  aria-label={ocAriaLabel}
>
  <slot />
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  /*                                                                                              */
  :host {
    font: tokens.$oc-component-link-100-font;
  }

  :host([size="inherit"]) {
    font: inherit;
  }

  :host([size="50"]) {
    font: tokens.$oc-component-link-50-font;
  }

  :host([size="75"]) {
    font: tokens.$oc-component-link-75-font;
  }

  :host([size="125"]) {
    font: tokens.$oc-component-link-125-font;
  }

  .link {
    /*                    */
    display: inline;
    cursor: pointer;

    position: relative;

    background: none;
    border: none;
    padding: 0;

    box-sizing: border-box;
    text-decoration: tokens.$oc-component-link-primary-text-decoration;
    color: tokens.$oc-component-link-primary-text-color;
    font: inherit;

    & {
      /*                                                                   */
      @include mixins.focus-styles(0.01px);
    }

    & {
      @include mixins.no-tap-highlight();
    }

    &--primary {
      color: tokens.$oc-component-link-primary-text-color;
    }

    &--secondary {
      color: tokens.$oc-component-link-secondary-text-color;
    }

    &--underlined,
    &--underlined-bold {
      color: tokens.$oc-component-link-underlined-text-color;
      text-decoration: tokens.$oc-component-link-underlined-text-decoration;
    }

    &--underlined-bold {
      font-weight: tokens.$oc-component-link-underlined-bold-font-weight;
    }
  }

  @media (hover: hover) {
    :host(:hover:not(:disabled)) .link {
      &--primary {
        text-decoration: tokens.$oc-component-link-primary-text-decoration-hover;
        color: tokens.$oc-component-link-primary-text-color-hover;
      }
      &--secondary {
        text-decoration: tokens.$oc-component-link-secondary-text-decoration-hover;
        color: tokens.$oc-component-link-secondary-text-color-hover;
      }
      &--underlined,
      &--underlined-bold {
        color: tokens.$oc-component-link-underlined-text-color-hover;
      }
    }
  }

  :host(:active:not(:disabled)) .link {
    &--primary {
      text-decoration: tokens.$oc-component-link-primary-text-decoration-hover;
      color: tokens.$oc-component-link-primary-text-color-active;
    }
    &--secondary {
      text-decoration: tokens.$oc-component-link-secondary-text-decoration-hover;
      color: tokens.$oc-component-link-secondary-text-color-active;
    }
    &--underlined,
    &--underlined-bold {
      color: tokens.$oc-component-link-underlined-text-color-active;
    }
  }
</style>
