/**
 *
 *
 */

export function disableScrolling(): void {
  const { scrollY } = window;
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollY}px`;
  document.body.style.overscrollBehaviorY = "none";
}

export function enableScrolling(): void {
  const scrollY = document.body.style.top;
  document.body.style.removeProperty("position");
  document.body.style.removeProperty("top");
  document.body.style.overscrollBehaviorY = "";
  window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
}
