<svelte:options
  customElement={{
    tag: "oc-carousel-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      arrowButtonPosition: { type: "String", attribute: "arrow-button-position" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";
  import { ArrowButtonV1 } from "../../../common/components/ArrowButton";
  import PaginationDotsV1 from "../../../common/components/PaginationDotsV1/PaginationDotsV1.svelte";
  import type { Props } from "./CarouselV1.types";

  export let ocAriaLabel: Props["ocAriaLabel"];
  export let arrowButtonPosition: Exclude<Props["arrowButtonPosition"], undefined>;

  export let host: HTMLElement;

  let currentSlide = 0;

  let slideCount = host.childElementCount;
  let carouselStage: HTMLElement;

  /*                                                        */
  let hideLeftArrow = true;
  let hideRightArrow = true;

  onMount(() => {
    const intersectionObserverOptions: IntersectionObserverInit = {
      root: carouselStage,
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          currentSlide = Array.from(host.children).indexOf(entry.target);
        }
      });

      hideLeftArrow = currentSlide === 0;
      hideRightArrow = currentSlide === slideCount - 1;
    }, intersectionObserverOptions);

    /*                 */
    Array.from(host.children).forEach((child) => {
      observer.observe(child);
    });

    slideCount = host.childElementCount;
    new MutationObserver((mutations) => {
      mutations.forEach(({ removedNodes, addedNodes }) => {
        addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) observer.observe(node);
        });
        removedNodes.forEach((node) => {
          if (node instanceof HTMLElement) observer.unobserve(node);
        });
      });
      slideCount = host.childElementCount;
    }).observe(host, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  const moveTo = (target: number) => {
    if (target >= 0 && target <= slideCount - 1) {
      const item = host.children.item(target);
      if (item instanceof HTMLElement) {
        carouselStage.scrollLeft = item?.offsetLeft;
      }
    }
  };
</script>

<div class="carousel">
  <section class="carousel__stage" bind:this={carouselStage} aria-label={ocAriaLabel}>
    <slot />
  </section>
  <ArrowButtonV1
    hidden={hideLeftArrow}
    direction="left"
    {arrowButtonPosition}
    onClick={() => moveTo(currentSlide - 1)}
  ></ArrowButtonV1>
  <ArrowButtonV1
    hidden={hideRightArrow}
    direction="right"
    {arrowButtonPosition}
    onClick={() => moveTo(currentSlide + 1)}
  ></ArrowButtonV1>
</div>

<PaginationDotsV1 amountDots={slideCount} selectedDot={currentSlide} {moveTo} />

<style lang="scss" global>
  @use "../../../common/scss/mixins/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  /*                                         */
  ::slotted(*) {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    flex: 0 0 100%;
  }

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .carousel {
    position: relative;

    &__stage {
      display: flex;
      gap: tokens.$oc-component-carousel-stage-gap-x;
      overflow-x: auto;
      scrollbar-width: none; /*     */
      scroll-snap-type: x mandatory;

      /*                                         */
      margin: -4px;
      padding: 4px;

      /*                                    */
      @supports (scroll-snap-type: x mandatory) {
        scroll-snap-type: x mandatory;
      }

      /*                                    */
      @supports (scroll-behavior: smooth) {
        scroll-behavior: smooth;
      }

      /*                                    */
      @supports (scroll-behavior: smooth) and (scroll-snap-type: x mandatory) {
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
      }

      /*                                            */
      @media (prefers-reduced-motion) {
        scroll-behavior: auto;
      }

      &::-webkit-scrollbar {
        display: none; /*                */
      }
    }
  }
</style>
