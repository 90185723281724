<script lang="ts">
  /**
 *
 *
 *
 *
 */
  import { tick } from "svelte";

  /**
 *
 */
  export let asButton: boolean = false;

  /**
 *
 */
  export let href = "";

  /**
 *
 *
 */
  export let base64Href: string | undefined = undefined;

  /**
 *
 */
  export let interactiveClass: string | undefined = undefined;

  export let thisElement: HTMLElement | undefined = undefined;

  /**
 *
 *
 */
  const unmaskHref = async (ev: Event) => {
    if (base64Href) {
      /*                    */
      href = atob(base64Href);
      base64Href = undefined;

      /*                                  */
      if (ev.constructor.name === "FocusEvent") {
        /*                                     */
        await tick();
        /*                    */
        /*                                                     */
        thisElement?.focus();
      }
    }
  };

  /*                                                               */
  $: if (thisElement) (thisElement as HTMLAnchorElement).href = href;

  /*               */
  /*                                         */
  $: tag = base64Href ? "div" : href ? "a" : asButton ? "button" : "div";

  /*                                           */
  $: tabindex = base64Href ? 0 : undefined;
  $: role = base64Href ? "link" : undefined;

  /*                                  */
  $: isInteractive = tabindex === 0 || thisElement?.tabIndex === 0;

  /*                                                                        */
  $: className = `${$$restProps.class ?? ""} ${(isInteractive && interactiveClass) || ""}`;
</script>

<!--order of props is important-->
<!--...$$restProps as late as possible to allow overwriting-->
<!--...$$restProps before class otherwise $$restProps.class overwrites class-->
<svelte:element
  this={tag}
  bind:this={thisElement}
  on:focus|once={unmaskHref}
  on:mouseover|once={unmaskHref}
  on:touchstart|once={unmaskHref}
  {tabindex}
  {role}
  {...$$restProps}
  class={className}
>
  <slot></slot>
</svelte:element>
