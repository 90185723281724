<svelte:options
  customElement={{
    tag: "oc-sheet-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      open: { type: "Boolean" },
      label: { type: "String" },
      id: { type: "String", reflect: true },
      url: { type: "String" },
      base64Url: { type: "String", attribute: "base64-url" },
      hideHeader: { type: "Boolean", attribute: "hide-header" },
      hideCloseButton: { type: "Boolean", attribute: "hide-close-button" },
      fullHeight: { type: "Boolean", attribute: "full-height" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { tick } from "svelte";
  /*                                                         */
  import { getPointerType } from "@otto-ec/global-resources/device";
  import type { Props } from "./SheetV1.types";
  import { useSlots } from "../../../common/utils/useSlots";
  import { disableScrolling, enableScrolling } from "../../../common/utils/scrollPrevention";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import { CLOSE_TYPES, type CloseTypes, useEvents } from "./SheetV1.events";
  import * as shared from "./SheetV1.shared";
  import { closeTrackingContext, createTrackingContext } from "./SheetV1.tracking";

  usePropertyChange();

  export let open: Props["open"] = false;
  export let label: Props["label"] = undefined;
  export let id: Props["id"] = "";
  export let url: Props["url"] = undefined;
  export let base64Url: Props["base64Url"] = undefined;
  export let hideHeader: Props["hideHeader"] = false;
  export let hideCloseButton: Props["hideCloseButton"] = false;
  export let fullHeight: Props["fullHeight"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let host: HTMLOcSheetV1Element;

  const slots = useSlots();

  let sheetElement: HTMLDivElement;
  let contentElement: HTMLDivElement;
  let loadedContentContainerElement: HTMLDivElement;
  let closeButtonElement: HTMLOcIconButtonV1Element;
  let backButtonElement: HTMLOcIconButtonV1Element;
  let visible = false; /*                           */
  let displayHidden = true; /*                                      */
  let previousSheet: string | null = null; /*                                               */
  let transition = true; /*                                                                         */
  let loadedContent: DocumentFragment; /*                               */
  let contentLoadingError = false;
  let contentHeight: number; /*                                                */
  let isScrolling = false;
  let isScrollEnd = false;
  let hasContentOverflow = false; /*                                                    */
  let dragging = false; /*                                                      */
  let touchStartY = 0; /*                                         */
  let dragDistance = 0; /*                                                         */
  let dragOffsetY = "0"; /*                                                              */
  let animationTimeout: number; /*                                             */
  let closeType: CloseTypes | null; /*                                               */
  let hasBackButton = false;
  let isTouchableDevice = false; /*                                                             */

  const OPEN_CLOSE_ANIMATION_DURATION = 1000;

  /*                   */
  const {
    openSheetID,
    openSheet,
    documentScrollingDisabled,
    isBackNavigation,
    sheetHistory,
    activeElement,
  } = shared;

  const { emitEvent } = useEvents();

  getPointerType().then((pointerType) => {
    isTouchableDevice = pointerType.isTouchable;
  });

  function insideStorybook() {
    /*                                                  */
    return !!window.CONFIG_TYPE;
  }

  function disableDocumentScrolling() {
    if (insideStorybook() && window.parent !== window.self) {
      /*                                                                      */
      return;
    }
    if (!$documentScrollingDisabled) {
      /*           */
      $documentScrollingDisabled = true;
      disableScrolling();
    }
  }

  function enableDocumentScrolling() {
    if (insideStorybook() && window.parent !== window.self) {
      /*                                                 */
      return;
    }
    /*           */
    if ($documentScrollingDisabled) {
      $documentScrollingDisabled = false;
      enableScrolling();
    }
  }

  function handleESCKey(e: KeyboardEvent) {
    if (e.code === "Escape" && open) {
      closeType = CLOSE_TYPES.CLOSED_WITH_ESCAPE;
      open = false;
    }
  }

  function addESC() {
    window.document.addEventListener("keydown", handleESCKey);
  }

  function removeESC() {
    window.document.removeEventListener("keydown", handleESCKey);
  }
  async function loadContent() {
    const contentURL = base64Url ? atob(base64Url) : url;
    if (!contentURL) {
      return;
    }
    try {
      loadedContent = await shared.loadContent(contentURL);
      /*                                                                                                        */
      createTrackingContext(id, contentURL);
      /*                       */
      await tick();
      loadedContentContainerElement.appendChild(loadedContent);
      emitEvent("contentLoaded", { instance: $openSheet! });
    } catch (error) {
      contentLoadingError = true;
      emitEvent("contentLoadingError", { instance: $openSheet!, error });
    }
  }

  function manageFocus(e: MouseEvent) {
    if (e.detail === 0 && $activeElement) {
      $activeElement.focus();
    }
  }

  function onCloseButtonClick(e: MouseEvent) {
    manageFocus(e);
    closeType = CLOSE_TYPES.CLOSED_WITH_X;
    open = false;
  }

  async function onBackdropClick() {
    closeType = CLOSE_TYPES.CLOSED_WITH_CURTAIN_CLICK;
    open = false;
  }

  function onSwipeDown() {
    closeType = CLOSE_TYPES.CLOSED_WITH_DRAG;
    open = false;
  }

  function onBackButtonClick() {
    shared.goBack();
  }

  function addToHistory(sheetID: string) {
    if (!$isBackNavigation) {
      /*                                  */
      $sheetHistory = [...$sheetHistory, sheetID];
    } else {
      $isBackNavigation = false;
    }
  }

  function clearHistory() {
    $sheetHistory = [];
  }

  /**
 *
 */
  function onScroll() {
    const scrollY = contentElement.scrollTop;
    isScrolling = scrollY > 0 && !hideHeader;
    isScrollEnd =
      contentElement.offsetHeight + contentElement.scrollTop >= contentElement.scrollHeight - 1;
  }

  /**
 *
 *
 */
  function onContentHeightChange(height: number) {
    if (contentElement) {
      hasContentOverflow = contentElement.scrollHeight !== height;
    }
  }

  function touchStart(event: TouchEvent) {
    dragging = true;
    touchStartY = event.touches[0].clientY;
  }
  function onTouchStartHeader(event: TouchEvent) {
    touchStart(event);
  }

  function onTouchStartContent(event: TouchEvent) {
    if (!isScrolling) {
      touchStart(event);
    }
  }

  function touchMove(event: TouchEvent) {
    if (dragging) {
      dragDistance = event.touches[0].clientY - touchStartY;
      if (dragDistance > 0) {
        /*                                                     */
        event.preventDefault();
      }
    }
  }

  function onTouchMoveContent(event: TouchEvent) {
    if (isScrolling) {
      /*                                          */
      /*                                                                                     */
      dragging = false;
    }
    touchMove(event);
  }

  function onTouchMoveHeader(event: TouchEvent) {
    touchMove(event);
  }

  function onTouchEnd() {
    const CLOSING_DISTANCE = 100;
    if (dragging && dragDistance > CLOSING_DISTANCE) {
      onSwipeDown();
    }
    dragDistance = 0;
    dragging = false;
  }

  /*                                                                                               */
  function trapFocus() {
    /*                                                       */
    if (insideStorybook() && window.parent !== window.self) {
      /*                                      */
      return;
    }
    if (hasBackButton) {
      backButtonElement.focus();
    } else {
      closeButtonElement.focus();
    }
  }

  function onHidden(hidden: boolean) {
    host.style.display = hidden ? "none" : "";
  }

  function reset() {
    transition = true;
    clearHistory();
    enableDocumentScrolling();
    $openSheet = null;
    $activeElement = null;
    closeType = null;
  }

  async function showSheet() {
    /*                                                                              */
    displayHidden = false;
    /*                   */
    open = true;
    /*                                     */
    await tick();
    visible = true;
    sheetElement.focus();
    addESC();
  }

  function hideSheet() {
    visible = false;
    open = false; /*                   */
    /*                                                */
    animationTimeout = window.setTimeout(() => {
      displayHidden = true;
    }, OPEN_CLOSE_ANIMATION_DURATION);
    removeESC();
  }

  async function openThisSheet() {
    /*                                       */
    clearTimeout(animationTimeout);
    $openSheet = host;
    /*                                    */
    if (previousSheet) {
      transition = false;
      /*                                          */
      addToHistory(previousSheet);
      showSheet().then();
      emitEvent("switch", { instance: $openSheet });
    } else {
      transition = true;
      disableDocumentScrolling();
      /*                                                                             */
      $activeElement = (window.document.activeElement as HTMLElement) ?? null;
      showSheet().then();
      emitEvent("open", { instance: $openSheet });
    }
    if ((url || base64Url) && !loadedContent) {
      await loadContent();
    }
  }

  function closeThisSheet(_openSheetID: string | null) {
    if (loadedContent) {
      /*                                                  */
      closeTrackingContext();
    }
    /*                                         */
    if (_openSheetID) {
      transition = false;
      hideSheet();
    } else {
      /*                                                             */
      hideSheet();
      closeType = closeType ?? CLOSE_TYPES.CLOSED_PROGRAMMATICALLY;
      emitEvent("close", { instance: $openSheet!, closeType });
      reset();
    }
  }

  function onSheetChange(_openSheetID: string | null) {
    if (_openSheetID === id) {
      openThisSheet();
    } else if (_openSheetID !== id && visible) {
      closeThisSheet(_openSheetID);
    }
    /*                             */
    previousSheet = _openSheetID;
  }

  async function onOpen(_open: boolean | undefined) {
    if (_open) {
      $openSheetID = id;
    }
    /*                                                       */
    if (!_open && $openSheetID === id) {
      $openSheetID = null;
    }
  }

  /*                 */
  $: onOpen(open);
  $: onSheetChange($openSheetID);
  $: onHidden(displayHidden);
  $: onContentHeightChange(contentHeight);
  /*                                                                  */
  $: dragOffsetY = dragging && dragDistance > 0 ? `${dragDistance}px` : "0";
  $: hasBackButton = !!$sheetHistory.length;

  /*               */
  export function getContent() {
    return shared.getContent(host);
  }

  export function getHeader() {
    return shared.getHeader(host);
  }

  export function getActions() {
    return shared.getActions(host);
  }
</script>

{#if id}
  <div
    class="sheet"
    class:sheet--open={visible}
    class:sheet--no-transition={!transition}
    class:sheet--no-header={hideHeader}
    class:sheet--full-height={fullHeight}
    class:sheet--is-touchable={isTouchableDevice}
  >
    <!-- Focus trap start: triggers .sheet__sheet:not(:focus-within) on focus  -->
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <span class="sheet__focus-trap" tabindex="0" aria-hidden="true"></span>
    <div class="sheet__backdrop" on:click={onBackdropClick} aria-hidden="true"></div>
    <div
      class="sheet__sheet"
      bind:this={sheetElement}
      class:sheet__sheet--loaded={loadedContent}
      style={dragging ? `transform:translateY(${dragOffsetY})` : ""}
      role="dialog"
      aria-label={ocAriaLabel || label}
      aria-modal="true"
      tabindex="-1"
    >
      <!--SHEET HEADER-->
      <div
        class="sheet__header"
        class:sheet__header--with-shadow={isScrolling}
        on:touchstart={onTouchStartHeader}
        on:touchmove={onTouchMoveHeader}
        on:touchend={onTouchEnd}
      >
        {#if hasBackButton}
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <oc-icon-button-v1
            bind:this={backButtonElement}
            on:click={onBackButtonClick}
            iconType="arrow-left"
            elevation={hideHeader ? "200" : "0"}
            class="sheet__back-button"
            ocAriaLabel="zurück"
          ></oc-icon-button-v1>
        {/if}
        <div class="sheet__label-outer">
          {#if label}
            <h3 id="dialog-label" class="sheet__label">
              {label}
            </h3>
          {/if}
        </div>
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <oc-icon-button-v1
          bind:this={closeButtonElement}
          on:click={onCloseButtonClick}
          class:sheet__close-button--hidden={hideCloseButton}
          iconType="close"
          elevation={hideHeader ? "200" : "0"}
          class="sheet__close-button"
          ocAriaLabel="schließen"
        ></oc-icon-button-v1>
      </div>
      <!--SHEET CONTENT-->
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <div
        class="sheet__content"
        tabindex="0"
        class:sheet__content-no-scrolling={dragging}
        on:scroll={onScroll}
        bind:this={contentElement}
        bind:clientHeight={contentHeight}
        on:touchstart={onTouchStartContent}
        on:touchmove|stopPropagation={onTouchMoveContent}
        on:touchend={onTouchEnd}
      >
        <!--LOADED CONTENT-->
        <div
          class="sheet__loaded-content-outer"
          class:sheet__loaded-content-outer--visible={url && loadedContent}
        >
          <div bind:this={loadedContentContainerElement} class="sheet__loaded-content"></div>
        </div>

        <!--CONTENT SLOT-->
        {#if !loadedContent && !contentLoadingError}
          <slot />
        {/if}

        <!--LOADING-ERROR SLOT-->
        {#if contentLoadingError}
          <div class="sheet_loading-error">
            <slot name="loading-error"></slot>
          </div>
        {/if}
      </div>

      <!--SHEET FOOTER-->
      {#if $slots.actions}
        <div
          class="sheet__footer"
          class:sheet__footer--with-shadow={hasContentOverflow && !isScrollEnd}
        >
          <div class="sheet__actions">
            <slot name="actions" />
          </div>
        </div>
      {/if}
      <span on:transitionend={trapFocus} class="sheet__focus-helper"></span>
    </div>
    <!-- Focus trap end: triggers .sheet__sheet:not(:focus-within) on focus -->
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <span class="sheet__focus-trap" tabindex="0" aria-hidden="true"></span>
  </div>
{:else}
  <!--  eslint-disable-next-line svelte/no-at-html-tags-->
  {@html "<!-- Property ID is missing! Please set the 'id' attribute/property to a unique value -->"}
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  .sheet {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    overscroll-behavior-y: none;

    &__backdrop {
      position: fixed;
      right: 0;
      bottom: 0;
      background-color: tokens.$oc-component-sheet-scrim-color;
      opacity: 0;
      transition: opacity ease 0.3s;
    }

    &__sheet {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      top: initial;
      max-height: 80vh;
      max-height: 80dvh;
      display: flex;
      flex-direction: column;
      background: tokens.$oc-component-sheet-content-default-background-color;
      transform: translateY(100%);
      transition: all tokens.$oc-component-sheet-show-duration
        tokens.$oc-component-sheet-show-easing;
      border-radius: tokens.$oc-component-sheet-border-radius
        tokens.$oc-component-sheet-border-radius 0 0;
      overflow: auto;
      z-index: 9001;

      &:focus {
        outline: none;
      }
    }

    &__header {
      position: relative;
      z-index: 1;
      display: flex;
      grid-template-columns: auto 1fr auto;
      gap: tokens.$oc-component-sheet-header-gap-x;
      align-items: flex-start;
      /*                           */
      padding: 20px 12px 7px 16px;
      /*                                                          */
      flex-shrink: 0;
      background: tokens.$oc-component-sheet-header-background-color;
      border-bottom: tokens.$oc-component-sheet-header-conditional-border-bottom-width solid
        transparent;
      transition: border-color tokens.$oc-component-sheet-conditional-border-transition-duration
        tokens.$oc-component-sheet-conditional-border-transition-easing;

      &--with-shadow {
        border-color: tokens.$oc-component-sheet-header-conditional-border-bottom-color;
      }
    }

    &__label-outer {
      flex: 1 0;
    }

    &__label {
      flex: 1 1 auto;
      color: tokens.$oc-component-sheet-header-title-color;
      font: tokens.$oc-component-sheet-header-title-font;
      margin-top: 4px;
      margin-bottom: 16px;
      user-select: none;
    }

    &__back-button {
      margin-left: -4px;
    }

    &__close-button {
      &--hidden {
        /*                                 */
        width: 0;
        height: 0;
        display: block;
        overflow: hidden;
      }
    }

    &__content {
      flex-grow: 0;
      overflow: auto;
      overscroll-behavior: contain;
      min-height: 72px; /*                                                                     */

      &:focus {
        outline-offset: -2px !important;
        & {
          @include mixins.focus-styles(tokens.$oc-component-button-50-border-radius);
        }
      }

      &--no-scrolling {
        overflow: hidden;
      }
    }

    &__loaded-content-outer {
      display: grid;
      grid-template-rows: 0fr;
      /*                    */
      transition: grid-template-rows tokens.$oc-component-sheet-show-duration
        tokens.$oc-component-sheet-show-easing;
    }

    &__loaded-content-outer--visible {
      grid-template-rows: 1fr;
    }

    &__loaded-content {
      overflow: hidden;
    }

    &__footer {
      padding: 16px 16px 30px 16px;
      border-top: tokens.$oc-component-sheet-action-bar-conditional-border-top-width solid
        transparent;
      transition: border-color tokens.$oc-component-sheet-conditional-border-transition-duration
        tokens.$oc-component-sheet-conditional-border-transition-easing;

      &--with-shadow {
        background-color: tokens.$oc-component-sheet-action-bar-background-color;
        border-color: tokens.$oc-component-sheet-action-bar-conditional-border-top-color;
      }

      slot {
        filter: none;
      }
    }

    &__actions {
      filter: none;
    }

    &__focus-trap {
      /*                                 */
      width: 0;
      height: 0;
      display: block;
      overflow: hidden;
    }

    &__focus-helper {
      /*                                  */
      width: 0;
      height: 0;
      display: block;
      opacity: 0;
    }
  }

  /*        */
  .sheet--open {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .sheet__sheet {
      transform: translateY(0);

      /*                                   */
      /*                                                                                                      */
      /*                                                                        */
      &:not(:focus-within) .sheet__focus-helper {
        transition: opacity 0.01s;
        opacity: 0.1;
      }
    }

    .sheet__backdrop {
      left: 0;
      top: 0;
      opacity: 0.75;
    }
  }

  .sheet--no-transition {
    .sheet__backdrop {
      transition: none;
    }
  }

  .sheet--no-header {
    .sheet__header {
      background: none;
      padding-top: 12px;
    }

    .sheet__label {
      display: none;
    }

    .sheet__back-button {
      margin-top: 0;
    }

    .sheet__content {
      margin-top: -72px;
    }
  }

  .sheet--full-height {
    .sheet__sheet {
      height: 80vh;
      height: 80dvh;
    }
    .sheet__content {
      flex-grow: 1;
    }
  }

  .sheet--full-height.sheet--no-transition {
    .sheet__sheet {
      transition: none;
    }
  }

  .sheet--is-touchable {
    .sheet__header::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: tokens.$oc-component-sheet-drag-indicator-spacing-top;
      width: tokens.$oc-component-sheet-drag-indicator-width;
      height: tokens.$oc-component-sheet-drag-indicator-height;
      border-radius: tokens.$oc-component-sheet-drag-indicator-border-radius;
      background-color: tokens.$oc-component-sheet-drag-indicator-color;
    }
  }

  /*                                    */
  /*                                                                            */
  @media (min-width: 480px) {
    .sheet {
      &__sheet {
        top: 0;
        left: initial;
        width: 448px;
        max-height: none;
        transform: translateX(100%);
        border-radius: tokens.$oc-component-sheet-border-radius 0 0
          tokens.$oc-component-sheet-border-radius;
      }
    }

    .sheet--open {
      .sheet__sheet {
        /*                                                                                                 */
        /*                                      */
        transform: translateX(0) !important;
      }

      .sheet__header {
        /*                                                                     */
        pointer-events: none;
      }

      .sheet__back-button,
      .sheet__close-button {
        /*                                               */
        pointer-events: all;
      }
    }
    .sheet--no-transition {
      .sheet__sheet {
        transition: none;
      }
    }
    .sheet--full-height {
      .sheet__sheet {
        height: auto;
      }
    }
    .sheet--is-touchable {
      .sheet__header::after {
        content: none;
      }
    }
  }
</style>
