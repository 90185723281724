import type { EventTopic, ExtendedPromise, QueuedFunction } from "@otto-ec/global-resources/nexus";
import { components } from "../../../common/component.js";
import type { GetSheetDetailByName } from "./SheetV1.events";

export type SheetV1Open =
  /**
 *
 *
 *
 *
 */
  (sheetID: string) => ExtendedPromise<void>;

export type SheetV1Close =
  /**
 *
 *
 */
  () => ExtendedPromise<void>;

export type SheetV1Back =
  /**
 *
 *
 */
  () => ExtendedPromise<void>;

export type SheetV1GetOpenInstance =
  /**
 *
 */
  () => ExtendedPromise<HTMLOcSheetV1Element | null>;

export type SheetV1GetContent =
  /**
 *
 *
 */
  () => ExtendedPromise<HTMLElement | null>;

export type SheetV1GetHeader =
  /**
 *
 *
 */
  () => ExtendedPromise<HTMLElement | null>;

export type SheetV1GetActions =
  /**
 *
 *
 */
  () => ExtendedPromise<HTMLElement | null>;

declare global {
  interface OttoComponentApi {
    /**
 *
 */
    sheetV1: {
      open: QueuedFunction<SheetV1Open>;
      close: QueuedFunction<SheetV1Close>;
      back: QueuedFunction<SheetV1Back>;
      getOpenSheet: QueuedFunction<SheetV1GetOpenInstance>;
      getContent: QueuedFunction<SheetV1GetContent>;
      getHeader: QueuedFunction<SheetV1GetHeader>;
      getActions: QueuedFunction<SheetV1GetActions>;
      /*     */
      openEvent: EventTopic<GetSheetDetailByName<"oc-open">>;
      closeEvent: EventTopic<GetSheetDetailByName<"oc-close">>;
      switchEvent: EventTopic<GetSheetDetailByName<"oc-switch">>;
      contentLoadedEvent: EventTopic<GetSheetDetailByName<"oc-content-loaded">>;
      contentLoadingErrorEvent: EventTopic<GetSheetDetailByName<"oc-content-loading-error">>;
    };
  }
}

/*                                           */
const { sheetV1 } = components;
/*                                                                              */
export { sheetV1 as sheet };
/*                       */
export const {
  open,
  close,
  back,
  getOpenSheet,
  getHeader,
  getContent,
  getActions,
  openEvent,
  closeEvent,
  switchEvent,
  contentLoadedEvent,
  contentLoadingErrorEvent,
} = sheetV1;
