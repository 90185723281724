import { getDefaultSubmitter } from "./form";

/**
 *
 *
 *
 *
 */
export function implicitSubmitHandler(internals: ElementInternals) {
  return (event: KeyboardEvent) => {
    if (event.key === "Enter" && internals?.form) {
      /*                              */
      const submitter = getDefaultSubmitter(internals.form);

      if (submitter) {
        /*                                                                               */
        /*                                                */
        if (!submitter.disabled) {
          submitter.click();
        }
      } else {
        /*                                            */
        internals.form.requestSubmit();
      }
    }
  };
}
