<svelte:options
  customElement={{
    tag: "oc-promo-card-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      mainHeadline: { type: "String", attribute: "main-headline" },
      overline: { type: "String" },
      footerHeadline: { type: "String", attribute: "footer-headline" },
      footerText: { type: "String", attribute: "footer-text" },
      asButton: { type: "Boolean", attribute: "as-button" },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      ocAriaVisibleElements: { type: "Array", attribute: "oc-aria-visible-elements" },
    },
  }}
/>

<script lang="ts">
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import { useFullPathHrefGetter } from "../../../common/utils/useFullPathHrefGetter";
  import type { OcAriaVisibleElement, Props } from "./PromoCardV1.types.js";
  import { useSlots } from "../../../common/utils/useSlots.js";
  import type { OcCardV2Events } from "../../card";

  usePropertyChange();
  useFullPathHrefGetter();

  const slots = useSlots();

  export let mainHeadline: Props["mainHeadline"] = undefined;
  export let overline: Props["overline"] = undefined;
  export let footerHeadline: Props["footerHeadline"] = undefined;
  export let footerText: Props["footerText"] = undefined;

  export let asButton: Props["asButton"] = false;
  export let href: Props["href"] = undefined;
  export let base64Href: Props["base64Href"] = undefined;

  export let ocAriaVisibleElements: Props["ocAriaVisibleElements"] = [
    "overline",
    "mainHeadline",
    "logo",
    "tags",
    "mainContent",
    "footerHeadline",
    "footerText",
  ];

  $: hasHeader = overline || mainHeadline || $slots.logo;
  $: hasFooter = footerHeadline || footerText;

  $: allAriaVisibleElements = ocAriaVisibleElements!;

  const isAriaHidden = (element: OcAriaVisibleElement) => {
    return !allAriaVisibleElements.includes(element);
  };

  const handlePropertyChange = (event: OcCardV2Events["oc-property-change"]) => {
    /*                                                                                  */
    /*                                                                                */
    if (event.detail.href) href = event.detail.href.current;
  };
</script>

<oc-card-v2
  {asButton}
  {href}
  {base64Href}
  class="promo-card"
  on:oc-property-change={handlePropertyChange}
>
  <article>
    {#if hasHeader}
      <header class="promo-card__header">
        <div class="promo-card__header-container">
          {#if overline}
            <span
              class="promo-card__header-container-overline"
              aria-hidden={isAriaHidden("overline")}>{overline}</span
            >
          {/if}
          {#if mainHeadline}
            <span
              class="promo-card__header-container-main-headline"
              aria-hidden={isAriaHidden("mainHeadline")}>{mainHeadline}</span
            >
          {/if}
        </div>
        {#if $slots.logo}
          <slot name="logo" aria-hidden={isAriaHidden("logo")} />
        {/if}
      </header>
    {/if}

    <div class="promo-card__body">
      <slot aria-hidden={isAriaHidden("mainContent")} />
      {#if $slots.tags}
        <div class="promo-card__tag-container">
          <slot name="tags" aria-hidden={isAriaHidden("tags")} />
        </div>
      {/if}
    </div>

    {#if hasFooter}
      <footer class="promo-card__footer">
        {#if footerHeadline}
          <span class="promo-card__footer-headline" aria-hidden={isAriaHidden("footerHeadline")}
            >{footerHeadline}</span
          >
        {/if}
        {#if footerText}
          <span class="promo-card__footer-text" aria-hidden={isAriaHidden("footerText")}
            >{footerText}</span
          >
        {/if}
      </footer>
    {/if}
  </article>
</oc-card-v2>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: block;
    position: relative;
    container-type: inline-size;
  }

  .promo-card {
    /*                                                                                 */
    /*                                                     */
    height: 100%;
    width: 100%;
  }

  .promo-card__header {
    display: flex;
    flex-direction: row;
    gap: tokens.$oc-component-promo-card-gap-logo;
    padding: tokens.$oc-component-promo-card-spacing-x tokens.$oc-component-promo-card-spacing-y;
    align-items: center;

    height: calc(5em - 2 * tokens.$oc-component-promo-card-spacing-y);

    @include mixins.breakpoint-from-l(false, $containerFirst: true) {
      height: calc(5.5em - 2 * tokens.$oc-component-promo-card-spacing-y);
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      /*                           */
      flex: 1;
      &,
      & > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-overline {
        font: tokens.$oc-component-promo-card-overline-font;
      }

      &-main-headline {
        font: tokens.$oc-component-promo-card-s-m-first-headline-font;
        @include mixins.breakpoint-from-l(false, $containerFirst: true) {
          font: tokens.$oc-component-promo-card-l-xl-first-headline-font;
        }
      }
    }
  }

  /*               */
  .promo-card__body {
    position: relative;
    overflow: hidden;
  }
  .promo-card__tag-container {
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
    position: absolute;
  }

  .promo-card__footer {
    display: flex;
    flex-direction: column;
    min-height: 24px;
    padding: 16px;

    &-headline {
      /*                         */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;

      font: tokens.$oc-component-promo-card-s-m-second-headline-font;
      @include mixins.breakpoint-from-l(false, $containerFirst: true) {
        font: tokens.$oc-component-promo-card-l-xl-second-headline-font;
      }
    }

    &-text {
      font: tokens.$oc-component-promo-card-text-font;
    }
  }
</style>
